import React from "react";
import {
  SimpleGrid,
  GridItem,
  Container,
  useDisclosure,
  useColorMode,
  Button,
  Image,
} from "@chakra-ui/react";
import Sidebar from "../components/Sidebar";
import { Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import Nav from "../components/NavBar";
import Loading from "../components/Loading";

import image from "../assets/images/topography.svg";
import img1 from "../assets/images/thems/ramadan/3.png";
// import img2 from "../assets/images/thems/ramadan/2.png";
import Footer from "../components/Footer";

import Anouncement from "../components/Anouncement";

function MainPage({ contextHolder, notification1, connection }) {
  ///////////////////////////////////////////////////////

  const { user, isLoading } = useSelector((store) => store.user);
  const { theme } = useSelector((store) => store.theme);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const { colorMode } = useColorMode();
  const backgroundColor = "#DFDBE5";
  const backgroundImage = "";
  // useEffect(() => {
  // 	// if (user) window.location.reload();
  // }, []);
  if (isLoading) return <Loading />;

  if (!user) {
    return <Navigate to="/register" />;
  }

  return (
    <Container
      pt={"20px"}
      paddingX={"5%"}
      minW={"100%"}
      minH={"100vh"}
      bgColor={colorMode === "dark" ? "#635E6C" : backgroundColor}
      bgImage={theme === 1 ? img1 : image}
      bgSize={"cover"}
    >
      {notification1}
      {contextHolder}
      {/* <CreateInfo /> */}
      <SimpleGrid
        columns={12}
        spacing="20px"
        opacity={colorMode === "dark" ? 0.96 : 0.92}
      >
        {/* <GridItem
					colSpan={12}
					rounded={"md"}
					bg={colorMode === "dark" ? "#424242" : "white"}
					h={"8rem"}
					// boxShadow={
					// 	"rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
					// }
				>
					<Image src={img2} maxH={"8rem"}></Image>
					
				</GridItem> */}
        <GridItem
          colSpan={12}
          // boxShadow={
          // 	"rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          // }
        >
          <Anouncement />
        </GridItem>
        <GridItem
          colSpan={12}
          boxShadow={
            "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          }
        >
          <Nav connection={connection} />
        </GridItem>
        <GridItem
          p={"1rem"}
          bg={colorMode === "dark" ? "#424242" : "white"}
          minH={"100%"}
          rounded={"lg"}
          display={["none", "none", "none", "block"]}
          colSpan={3}
          boxShadow={
            "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          }
        >
          <Sidebar
            colorMode={colorMode}
            onOpen={onOpen}
            isOpen={isOpen}
            onClose={onClose}
          />
        </GridItem>
        <GridItem
          bg={colorMode === "dark" ? "#424242" : "white"}
          boxShadow={
            "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          }
          minH={"100vh"}
          colSpan={[12, null, null, 9]}
          rounded={"lg"}
          p={"1rem"}
        >
          {/* <Image src={img2} height={500} width={"100%"} /> */}
          <Outlet />
        </GridItem>
        <GridItem
          colSpan={12}
          boxShadow={
            "rgba(0, 0, 0, 0.04) 0px 2px 6px, rgba(0, 0, 0, 0.02) 0px 4px 8px, rgba(0, 0, 0, 0.02) 0px 6px 12px"
          }
        >
          <Footer />
        </GridItem>
      </SimpleGrid>
    </Container>
  );
}

export default MainPage;
