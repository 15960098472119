export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");

  const user = result ? JSON.parse(result) : null;
  return user;
};

export const addNotificationsToLocalStorage = (notifications) => {
  localStorage.setItem("Notifications", JSON.stringify(notifications));
};

export const removeNotificationsToLocalStorage = () => {
  localStorage.removeItem("Notifications");
};

export const getNotificationsFromLocalStorage = () => {
  const result = localStorage.getItem("Notifications");

  const notifications = result ? JSON.parse(result) : [];
  return notifications;
};
export const addLanguageToLocalStorage = (lang) => {
  localStorage.setItem("language", lang);
};
export const getLanguageFromLocalStorage = () => {
  const result = localStorage.getItem("language");
  const lang = result ? result : "Deutsch";
  return lang;
};
// export const getProductFromLocalStorage = () => {};
// export const addProductToLocalStorage = () => {};
export const addProductsToLocalSorage = (products) => {
  localStorage.setItem("products", JSON.stringify(products));
};
export const getProductsFromLocalStorage = () => {
  const result = localStorage.getItem("products");
  if (result === undefined || result === null || result === "") return [];

  try {
    const products = JSON.parse(result);
    return products;
  } catch (error) {
    console.error("Error parsing JSON from localStorage:", error);
    return [];
  }
};
