import { createSlice } from "@reduxjs/toolkit";
import {
  addLanguageToLocalStorage,
  getLanguageFromLocalStorage,
} from "../utils/LocalStorage";

const Ger = {
  mainActions: { logOut: "Abmelden" },
  navBar: {
    uploadPhoto: "Profilbild ändern",
    submit: "submit",
  },
  alignerLabels: {
    orderCount: "Bestellmenge",
    lowerCount: "Anzahl Unterkiefer Aligner",
    upperCount: "Anzahl Oberkiefer Aligner",
  },
  sideBar: {
    addOrder: "Bestellung hinzufügen",
    addUser: "Benutzer erstellen",
    addProduct: "Produkt hinzufügen",
    statistics: "Statistiken",
    allUsers: "Alle Benutzer",
    allOrders: "Alle Bestellungen",
    navigation: "Navigation",
    clinics: "Kliniken",
    staffs: "Mitarbeiter",
    addAd: "Ankündigung hinzufügen",
  },
  error: {
    productRequired: "Sie müssen mindestens ein Produkt auswählen",
    date: "Datum ist erforderlich",
    id: "ID ist erforderlich",
    firstName: "Vorname ist erforderlich",
    lastName: "Nachname ist erforderlich",
    serverError: "Oops! ein Fehler ist aufgetreten.",
    failed: "Sendefehler",
    success: "Erfolgreich",
    forbidden: "Achtung! Sie haben keinen Zugriff auf diese Seite.",
    notFound: "Oops! die von Ihnen besuchte Seite existiert nicht.",
    sessionExpired: "Sitzung abgelaufen",
    validateTheAnswers: "alle Fragen beantworten",
  },
  filters: {
    priority: "Priorität",
    title: "Alle",
    urgent: "Dringend",
    normal: "Normal",
  },
  buttonsText: {
    newest: "Neueste",
    oldest: "Älteste",
    backButton: "Zurück zur Startseite",
    submit: "Absenden",
    next: "Weiter",
    back: "Zurück",
    selectFile: "Datei auswählen",
    export: "Exportieren",
    edit: "Bearbeiten",
    cancel: "Abbrechen",
    save: "Speichern",
    clear: "Entfernen",
    filter: "Filtern",
    delete: "Löschen",
    No: "Nein",
    yes: "Ja",
    CreateChat: "Chat erstellen",
    OpenChat: "Chat öffnen",
    create: "Erstellen",
    ignore: "Ignorieren",
    download: "Herunterladen",
    retrive: "Abrufen",
    activate: "Aktiv",
    inactivate: "Inaktiv",
    upload: "Datei hochladen",
    changeState: "Verander naar geleverd",
    totalSelected: "Totaal geselecteerd",
    markAsRead: "Als gelesen Markieren",
    update: "aktualisieren",
  },
  notifications: {
    createTitle: "neue Bestellung",
    updateTitle: "Aktualisieren",
    createOrder: "Neue Bestellung erstellt",
    updateChanges: "Bestellung aktualisiert",
    updateState: "Bestellstatus geändert zu",
    updatMyState: "Mein Bestellstatus wurde aktualisiert zu",
    updateState2: "hat den Bestellstatus geändert",
    updateMyState2: "Bestellstatus wurde geändert",
    btn1: "Abbrechen",
    btn2: "Aufrufen",
  },
  heading: {
    patientInformation: "Patienteninformation",
    orderDetails: "Bestelldetails",
    files: "Dateien",
    createANewAccount: "Ein neues Konto erstellen",
    selectType: "Typ auswählen",
    nameHint: "- nur alphabetische Zeichen verwenden.",
    passwordHint: "- sollte mehr als 8 Zeichen lang sein.",
    startDate: "Startdatum",
    endDate: "Enddatum",
    recycleBin: "Papierkorb",
    theProducts: "Die Produkte :",
    activation: "Aktivierung",
    chats: "Alle Chats",
    typing: "Tippen...",
  },
  orderLabels: {
    firstName: "Vorname",
    lastName: "Nachname",
    id: "PAT-Nummer",
    birthDate: "Geburtsdatum",
    order: "Bestellung",
    typeOfOrder: "Art der Bestellung",
    note: "Notiz",
    files: "Dateien",
    products: "Produkte",
    instructions: "Anweisungen",
  },
  orderType: {
    normal: "Normal",
    urgent: "Dringend",
  },
  stepText: {
    first: "Erste",
    second: "Zweite",
    third: "Dritte",
  },
  product: {
    enterTheProduct: "Produkt eingeben",
    addProduct: "Produkt hinzufügen",
  },
  userText: {
    avatar: "Avatar",
    username: "Benutzername",
    country: "Land",
    moreInfo: "Weitere Informationen",
    searchForUser: "Nach Benutzer suchen",
    page: "Seiten",
  },
  updateUser: {
    username: "Benutzername",
    name: "Name",
    gender: "Geschlecht",
    password: "Passwort",
    country: "Land",
    phone: "Telefon",
    drName: "Arztname",
    location: "Ort",
    email: "E-Mail",
  },
  gender: {
    male: "Männlich",
    female: "Weiblich",
  },
  roleText: {
    admin: "Administrator",
    staff: "Mitarbeiter",
    clinic: "Klinik",
  },

  allOrders: {
    clinicName: "Klinikname",
    patientInfo: "Patienteninformation",
    state: "Status",
    date: "Datum",
    fName: "Vorname",
    sName: "Nachname",
    idNumber: "PAT-Nummer",
    producs: "Produkte",
    priority: "Priorität",
  },
  allerts: {
    productHeader: "Änderungen verwerfen",
    productMessage:
      "Sind Sie sicher, dass Sie dieses Produkt aktivieren/deaktivieren möchten?",
    yes: "Ja",
    no: "Nein",
  },
  orderTabs: {
    clinic: "Klinikinformationen",
    order: "Bestellinformationen",
    files: "Dateien",
    chat: "Chat",
  },
  diaglogText: {
    Discard: "Änderungen verwerfen?",
    Sure: "Sind Sie sicher, dass Sie diese Bestellung löschen möchten?",
    titleChat: "Bitte einen Titel eingeben!",
    enterName: "Bitte einen Namen eingeben!",
    deleteOr: "Möchten Sie diese Bestellung störnieren/löschen?",
    firstNamemsg: "Sind Sie sicher, dass Sie den Vornamen ändern möchten von",
    to: "zu",
    lastNamemsg: "Sind Sie sicher, dass Sie den Nachnamen ändern möchten von",
    datemsg:
      "Sind Sie sicher, dass Sie das Geburtsdatum des Patienten ändern möchten von",
    idmsg: "Sind Sie sicher, dass Sie die Patienten-ID ändern möchten von",
  },
  controlers: {
    edit: "bearbeiten",
    save: "speichern",
  },
  toolTip: {
    download: "Dateien herunterladen",
    upload: "Dateien hochladen",
  },
  empty: {
    empty: "Keine Daten",
  },
  login: {
    signIn: "Anmelden",
    userName: "Benutzername",
    password: "Passwort",
    message: "Melden Sie sich bei Ihrem Konto an",
  },
  states: [
    "Ausstehend",
    "Bearbeitung",
    "Herstellung",
    "Unterwegs",
    "geliefert",

    "abgelehnt",
    "warten",
  ],
  role: {
    admin: "Administrator",
    staff: "Mitarbeiter",
    role: "Rolle",
  },
  progress: {
    saving: "Speichern...",
    loading: "Laden",
    from: "Von",
  },
  chat: {
    mark: "Als ungelesen markieren",
  },
};

const Eng = {
  mainActions: { logOut: "log out" },
  navBar: {
    uploadPhoto: "Upload Photo",
    submit: "submit",
  },
  alignerLabels: {
    orderCount: "order count",
    lowerCount: "Lower Jaw Aligner",
    upperCount: "Upper Jaw Aligner",
  },
  sideBar: {
    addOrder: "Add Order",
    addUser: "Create User",
    addProduct: "Add Product",
    statistics: "Statistics",
    allUsers: "All Users",
    allOrders: "All Orders",
    clinics: "Clinics",
    staffs: "Staffs",
    navigation: "Navigation",
    addAd: "Add Announcement",
  },
  error: {
    productRequired: "You chould Choice at least one product",
    date: "Date is Required",
    id: "id is required",
    firstName: "first Name is required",
    lastName: "last Name is required",
    serverError: "Sorry, something went wrong.",
    faild: "Submission Failed",
    success: "Successfully",
    forbidden: "Sorry, you are not allowed to access this page.",
    notFound: "Sorry, the page you visited does not exist.",
    sessionExpired: "session expired",
    validateTheAnswers: "answer all questions",
  },
  filters: {
    priority: "priority",
    title: "All",
    urgent: "urgent",
    normal: "Normal",
  },
  buttonsText: {
    newest: "Newest",
    oldest: "Oldest",
    backButton: "Back Home",
    submit: "Submit",
    next: "Next",
    back: "Back",
    selectFile: "Select File",
    export: "Export",
    edit: "Edit",
    cancel: "Cancel",
    save: "Save",
    clear: "Clear",
    filter: "Filter",
    delete: "Delete",
    No: "No",
    yes: "Yes",
    CreateChat: "Create Chat",
    OpenChat: "Open Chat",
    create: "Create",
    ignore: "Ignore",
    download: "Download",
    retrive: "Retrieve",
    activate: "Active",
    inactivate: "inactive",
    upload: "Upload Files",
    changeState: "Change to delivered",
    totalSelected: "Total Selected",
    markAsRead: "Mark all as read",
    update: "Update",
  },

  notifications: {
    createTitle: "New order",
    updateTitle: "Updated",
    createOrder: "created new order",
    updateChanges: "updated an order",
    updateState: "changed an order state to",
    updatMyState: "Your order state changed to",
    updateState2: "changed an order state",
    updateMyState2: "your order state changed",
    btn1: "Destroy All",
    btn2: "Check It",
  },
  heading: {
    patientInformation: "Patient information",
    orderDetails: "Order Details",
    files: "Files",
    createANewAccount: "Create a new account",
    selectType: "Select Type",
    nameHint: "- use alphapetic characters only.",
    passwordHint: "- should be more than 8 chars.",
    startDate: "Start date",
    endDate: "End date",
    recycleBin: "Recycle Bin",
    theProducts: "The Products :",
    activation: "Activation",
    chats: "All Chats",
    typing: "Typing...",
  },
  orderLabels: {
    firstName: "First Name",
    lastName: "Last Name",
    id: "ID",
    birthDate: "Birth Date",
    order: "Order",
    products: "Products",
    typeOfOrder: "Type of order",
    note: "Note",
    files: "Files",
    instructions: "instructions",
    // chat: "chat",
  },
  orderType: {
    normal: "Normal",
    urgent: "Urgent",
  },
  stepText: {
    first: "First",
    second: "Second",
    third: "Third",
  },
  product: {
    enterTheProduct: "Enter the product",
    addProduct: "Add Product",
  },
  userText: {
    avatar: "Avatar",
    username: "Username",
    country: "Country",
    moreInfo: "More Info",
    searchForUser: "Search for user",
    page: "pages",
  },
  updateUser: {
    username: "Username:",
    name: "Name",
    gender: "Gender",
    password: "Password",
    country: "Country",
    phone: "Phone",
    drName: "Doctor Name",
    location: "Location",
    email: "Email",
  },
  gender: {
    male: "Male",
    female: "Female",
  },
  roleText: {
    admin: "Admin",
    staff: "Staff",
    clinic: "Clinic",
  },
  allOrders: {
    clinicName: "Clinic Name",
    patientInfo: "Patient Information",
    state: "State",
    date: "Date",
    fName: "First Name",
    sName: "Second Name",
    idNumber: "Medical Number",
    producs: "Products",
    priority: "priority",
  },
  allerts: {
    productHeader: "Discard Changes",
    productMessage:
      "Are you sure you want to activate/deactivate this product?",
    yes: "Yes",
    no: "No",
  },
  orderTabs: {
    clinic: "Clinic Info",
    order: "Order Info",
    files: "Files",
    chat: "Chat",
  },
  diaglogText: {
    Discard: "Discard Changes?",
    Sure: "Are you sure you want to Delete This Order ?",
    titleChat: "write the title of this chat",
    enterName: "Enter a Name",
    deleteOr: "Are you want to Retrieve/Delete This Order ?",
    firstNamemsg: "Are you sure you want to change the Patient first name from",
    to: "to",
    lastNamemsg: "Are you sure you want to change the Patient last name from",
    datemsg: "Are you sure you want to change the Patient Birth Date from",
    idmsg: "Are you sure you want to change the Patient Id from",
  },
  controlers: {
    edit: "edit",
    save: "save",
  },
  toolTip: {
    download: "Download files",
    upload: "Upload files",
  },
  empty: {
    empty: "No Data",
  },
  login: {
    signIn: "Sign in",
    userName: "Username",
    password: "Password",
    message: "Log in to your account",
  },
  states: [
    "pending",
    "processing",
    "manufacturing",
    "shipping",
    "delivered",
    "rejected",
    "waiting",
  ],
  role: {
    admin: "Admin",
    staff: "Staff",
    role: "Role",
  },
  progress: {
    saving: "Saving...",
    loading: "Loading",
    from: "From",
  },
  chat: {
    mark: "Mark as Unread",
  },
};

const langSlice = createSlice({
  name: "lang",
  initialState: {
    language: getLanguageFromLocalStorage(),
    ...(getLanguageFromLocalStorage() === "English" ? Eng : Ger),
  },
  reducers: {
    changeLang(state) {
      if (state.language === "English") {
        state = { language: "Deutsch", ...Ger };
      } else {
        state = { language: "English", ...Eng };
      }
      addLanguageToLocalStorage(state.language);
      return state;
    },
  },
});
export const { changeLang } = langSlice.actions;
export default langSlice.reducer;
