import {
	Flex,
	Button,
	Grid,
	GridItem,
	Text,
	Input,
	useColorMode,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import React, { useState } from "react";
import { updateClinic, updateUser as updateUserAction } from "../features/user/UserSlice";
function EditableBox({ info, user }) {
	const dispatch = useDispatch();
	const { colorMode } = useColorMode();
	let [value, setValue] = useState(info.value);
	const [editable, setEditable] = useState(false);
	const changeHandler = (e) => {
		setValue(e.target.value);
	};
	const { updateUser } = useSelector((store) => store.lang);
	const revert = () => {
		setEditable(editable === false);
	};
	const submitHandler = () => {
		let index = "";
		const alias = {
			[updateUser.name]: "name",
			[updateUser.drName]: "doctorName",
			[updateUser.email]: "email",
			[updateUser.phone]: "phone",
		};

		index = alias[info.title];

		user[index] = value;
		// console.log(user);
		if (user.role === "Clinic") dispatch(updateClinic(user));
		else dispatch(updateUserAction(user));
		revert();
	};
	const cancle = () => {
		value = info.value;
		revert();
	};
	const { buttonsText } = useSelector((store) => store.lang);
	return (
		<Grid
			templateColumns={"repeat(7,1fr)"}
			p={"1rem"}
			borderBottom={"2px solid"}
			borderColor="gray.100"
		>
			<GridItem colSpan={2}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{info.title} :
				</Text>
			</GridItem>
			<GridItem colSpan={3}>
				{editable ? (
					<Input onChange={changeHandler} defaultValue={value} />
				) : (
					<Text
						fontSize={"1rem"}
						color={colorMode === "dark" ? "gray.100" : "gray.500"}
						fontWeight={"semibold"}
					>
						{value}
					</Text>
				)}
			</GridItem>
			<GridItem colSpan={2}>
				{editable ? (
					<Flex
						gap={"1rem"}
						justifyContent={"end"}
						fontSize={".9rem"}
					>
						<Button
							fontSize={"smaller"}
							colorScheme={"blue"}
							onClick={submitHandler}
						>
							{buttonsText.save}
						</Button>{" "}
						<Button onClick={cancle} fontSize={"smaller"}>
							{" "}
							{buttonsText.cancel}
						</Button>
					</Flex>
				) : (
					<Flex justifyContent={"end"}>
						<Button
							variant={"link"}
							colorScheme={"blue"}
							onClick={revert}
						>
							{buttonsText.edit}
						</Button>
					</Flex>
				)}
			</GridItem>
		</Grid>
	);
}

export default EditableBox;
