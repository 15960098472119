import {
  Badge,
  Card,
  Flex,
  Spacer,
  CardHeader,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  AlertDialogCloseButton,
  useDisclosure,
  CardBody,
  Tabs,
  TabIndicator,
  TabList,
  TabPanels,
  Tab,
  CardFooter,
  Button,
  GridItem,
  Input,
  IconButton,
  InputGroup,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logOut } from "../features/user/UserSlice";
import {
  getOrder,
  getOrderFromNotification,
  deleteOrder,
  clearOrder,
  UpdateFiles,
  AdminUpdateFiles,
} from "../features/order/orderSlice";
import Files from "../components/order/Files";
import HistoryOfEdit from "./HistoryOfEdit";
import Loading from "../components/Loading";
import OrderInfo from "../components/order/OrderInfo";
import ClinicInfo from "../components/order/ClinicInfo";

import { createChat, setActivePage } from "../features/chat/chatSlice";
import { BiMessageAdd } from "react-icons/bi";
import { HiChatAlt } from "react-icons/hi";
import { DownloadIcon } from "@chakra-ui/icons";
import { exportFile } from "../features/order/orderSlice";
import { Upload } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import UploadProgress from "../components/order/UploadProgress";
import DownloadProgress from "../components/order/DownloadProgres";
import Notes from "../components/order/Notes";
import BtnLoading from "../components/BtnLoading";
import {
  buildTheUis,
  clearTheData,
  getTheQuestions,
} from "../features/products/ProductSlice";
import { buildAnswersTree } from "../utils/Tools";
// import { removeData } from "../features/chat/chatSlice";
function OrderPage() {
  // const [tabIndex, setTabIndex] = useState(0);
  // const lastTabRef = useRef(null);
  // const [is_opn, setIsOpen] = useState("hidden");
  const location = useLocation();
  let id = location.state?.id;
  const navigate = useNavigate();
  const { heading, orderLabels, language } = useSelector((store) => store.lang);

  const dispatch = useDispatch();
  const { user: me } = useSelector((store) => store.user);
  useEffect(() => {
    if (me === undefined) dispatch(logOut());
  }, [me]);
  const [fileList, setFileList] = useState([]);

  const {
    isOpen: isOpenUpload,
    onOpen: onOpenUpload,
    onClose: onCloseUpload,
  } = useDisclosure();

  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      // console.log(file);
      setFileList((prevFileList) => [...prevFileList, file]);
      // console.log("hi");
      return false;
    },
    fileList,
  };

  const onSubmitUpload = (e) => {
    e.preventDefault();

    const formData = new FormData();

    for (let i = 0; i < fileList.length; i++) {
      formData.append("Images", fileList[i]);
    }
    if (user.role === "Admin") {
      dispatch(AdminUpdateFiles({ form: formData, id: id }));
    } else {
      dispatch(UpdateFiles({ form: formData, id: id }));
    }
    // console.log("upload image");
  };

  const cancelRefUpload = React.useRef();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const cancelRef = React.useRef();

  let notificationId = location.state?.notificationId;

  // if (id === null || id === undefined) navigate("/404");
  // const dispatch = useDispatch();
  useEffect(() => {
    dispatch(clearOrder());
    dispatch(clearTheData());
    dispatch(setActivePage(100));
  }, []);
  let chatId = useSelector((store) => store.order.order.chatId);
  const { isLoading: isLoadingChat } = useSelector((store) => store.chat);
  useEffect(() => {
    if (notificationId)
      dispatch(getOrderFromNotification({ id, notificationId }));
    else if (id) dispatch(getOrder(id));
    // dispatch(getTheQuestions());
  }, [dispatch, id]);
  const { isLoading, order, isUploading, isDownloading } = useSelector(
    (store) => store.order
  );
  // console.log(order);
  const { id: orderId, patient } = useSelector((store) => store.order.order);

  const submitHandler = () => {
    dispatch(
      createChat({ id: order.id, mRecord: patient.medicalRecordNumber })
    );

    // dispatch(getOrder(id));
  };

  ///////////////////////

  const {
    isOpen: isOpenFile,
    onOpen: onOpenFile,
    onClose: onCloseFile,
  } = useDisclosure();
  const cancelRefFile = React.useRef();
  const [name, setName] = useState(order.clinic.name);
  const handlerFile = (e) => {
    setName(e.target.value);
  };
  const onSubmitFile = () => {
    let imagesName = "";

    order.imageFiles.forEach((name, index) => {
      imagesName += `${index === 0 ? "" : "&"}files=${name.fileName}`;
    });

    dispatch(exportFile({ name, fileName: imagesName }));
  };
  const { uis } = useSelector((state) => state.products);
  let tree = [];
  // useEffect(() => {
  //   console.log(order);
  //   tree = buildAnswersTree(order.product, order.answerIds, language);
  // }, [order]);
  ////////////
  // console.log(order);
  const { orderTabs, orderType, buttonsText, diaglogText, toolTip } =
    useSelector((store) => store.lang);
  const { user } = useSelector((store) => store.user);
  // const { isLoading: loader } = useSelector((store) => store.chat);
  let isOkToClinic =
    user.role === "Clinic" && (order.state === 0 || order.state === 5);

  if (id === undefined) return <Navigate to={"/404"} />;

  if (isLoading) return <Loading />;
  if (isDownloading) return <DownloadProgress />;
  if (isUploading) return <UploadProgress></UploadProgress>;
  if (order.id === undefined) return <Navigate to={"/all-orders"} />;

  let utcTime = new Date(order.createdDate);
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
  const dateObject = new Date(localTime);

  const formattedDate = dateObject.toLocaleDateString("en-GB");

  // Format the time as "hh:mm a"
  const formattedTime = dateObject.toLocaleTimeString("en-US", {
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  });
  return (
    <Card color={"gray.500"}>
      <CardHeader>
        <Flex alignItems={"center"} gap={"0.4rem"}>
          {user.role === "Admin" && (
            <>
              <Button
                onClick={onOpen}
                fontSize={"lg"}
                fontWeight={"semibold"}
                colorScheme={"red"}
              >
                {buttonsText.delete}
              </Button>
              <AlertDialog
                motionPreset="slideInBottom"
                leastDestructiveRef={cancelRef}
                onClose={onClose}
                isOpen={isOpen}
                isCentered
              >
                <AlertDialogOverlay />

                <AlertDialogContent>
                  <AlertDialogHeader>{diaglogText.Discard}</AlertDialogHeader>
                  <AlertDialogCloseButton />
                  <AlertDialogBody>{diaglogText.Sure}</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={onClose}>
                      {buttonsText.No}
                    </Button>
                    <Button
                      colorScheme="red"
                      ml={3}
                      onClick={() => {
                        dispatch(deleteOrder(id));
                        onClose();
                      }}
                    >
                      {buttonsText.yes}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialog>
            </>
          )}
          {/* {user.role === "Admin" && <Spacer></Spacer>} */}
          <Flex gap={"0.4rem"}>
            {chatId !== null ? (
              <Link
                to={"/chat"}
                state={{
                  chatId: chatId,
                  clinicId: order.clinic.Id,
                }}
              >
                <Button
                  fontSize={"lg"}
                  color="white"
                  bgColor="#3667A6"
                  //fontColor={"blue"}
                  variant={"solid"}
                  rounded={"md"}
                  paddingX={"0.75rem"}
                  alignItems={"center"}
                  _hover={{
                    bgColor: "#214177",
                    transition: "background-color 0.3s ease-in-out",
                  }}
                >
                  <HiChatAlt style={{ marginRight: "0.5rem" }} />
                  {buttonsText.OpenChat}
                </Button>
              </Link>
            ) : isLoadingChat ? (
              <BtnLoading />
            ) : (
              <Button
                onClick={submitHandler}
                // onClick={onOpen2}
                // transform="scale(.8)"
                fontSize={"lg"}
                color="white"
                bgColor="#3667A6"
                //fontColor={"blue"}
                variant={"solid"}
                rounded={"md"}
                paddingX={"0.75rem"}
                alignItems={"center"}
                //icon={<EditIcon />}
                _hover={{
                  bgColor: "#214177",
                  transition: "background-color 0.3s ease-in-out",
                }}
                //  _hover={{ background ="blue.300" }}
              >
                <BiMessageAdd style={{ marginRight: "0.5rem" }} />
                {buttonsText.CreateChat}
                {/* create chat */}
              </Button>
            )}

            {user.role === "Admin" ||
            (user.role === "Clinic" &&
              (order.state === 0 || order.state === 5)) ? (
              <>
                <Tooltip label={toolTip.upload} placement="bottom">
                  <Button
                    ref={cancelRefUpload}
                    onClick={onOpenUpload}
                    fontSize={"lg"}
                    rounded={"md"}
                    paddingX={"0.75rem"}
                    alignItems={"center"}
                    //colorScheme={"blue"}
                    bg={"#D4F1F4"}
                    color={"#1E5162"}
                    borderColor={"transparent"}
                    _hover={{
                      bgColor: "#CEE6F3",
                      transition: "background-color 0.3s ease-in-out",
                    }}
                  >
                    <UploadOutlined />
                  </Button>
                </Tooltip>
                <AlertDialog
                  motionPreset="slideInBottom"
                  leastDestructiveRef={cancelRefUpload}
                  onClose={onCloseUpload}
                  isOpen={isOpenUpload}
                  isCentered
                >
                  <AlertDialogOverlay />

                  <AlertDialogContent>
                    <AlertDialogHeader>{toolTip.upload}</AlertDialogHeader>
                    <AlertDialogCloseButton />
                    <AlertDialogBody>
                      <InputGroup size="sm">
                        <Upload
                          multiple
                          {...props}
                          accept=".stl,.png,.jpg,.jpeg,.zip,.rar"
                        >
                          <Button icon={<UploadOutlined />}>
                            {buttonsText.selectFile}
                          </Button>
                        </Upload>
                      </InputGroup>
                    </AlertDialogBody>
                    <AlertDialogFooter>
                      <Button
                        type="submit"
                        colorScheme="red"
                        ml={3}
                        onClick={(e) => {
                          onSubmitUpload(e);
                          // dispatch(deleteOrder(id));

                          onCloseUpload();
                        }}
                      >
                        {/* upload Files */}
                        {buttonsText.upload}
                      </Button>
                    </AlertDialogFooter>
                  </AlertDialogContent>
                </AlertDialog>
              </>
            ) : (
              ""
            )}
          </Flex>

          <Spacer></Spacer>
          <Flex gap={"1rem"}>
            <Badge
              rounded={"full"}
              color={order.isUrgent ? "#821D30" : "#1E5162"}
              fontSize={"sm"}
              paddingX={"0.75rem"}
              alignSelf={"center"}
              bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
              textTransform={"hidden"}
            >
              {formattedDate + " " + formattedTime}
            </Badge>
            <Badge
              rounded={"full"}
              color={order.isUrgent ? "#821D30" : "#1E5162"}
              fontSize={"sm"}
              paddingX={"0.75rem"}
              alignSelf={"center"}
              bg={order.isUrgent ? "#FAE8E0" : "#D4F1F4"}
              textTransform={"hidden"}
            >
              {order.isUrgent ? orderType.urgent : orderType.normal}
            </Badge>
          </Flex>
        </Flex>
      </CardHeader>
      <CardBody>
        <Tabs position="relative" variant="unstyled">
          <TabList>
            <Tab
              // onClick={() => {
              // 	setIsOpen("hidden");
              // }}
              fontWeight={"bold"}
              _hover={{
                background: "#E2E8F0",
              }}
            >
              {orderTabs.clinic}
            </Tab>
            <Tab
              // onClick={() => {
              // 	setIsOpen("hidden");
              // }}
              fontWeight={"bold"}
              _hover={{
                background: "#E2E8F0",
              }}
            >
              {orderTabs.order}
            </Tab>
            <Tab
              // onClick={() => {
              // 	setIsOpen("hidden");
              // }}
              fontWeight={"bold"}
              _hover={{
                background: "#E2E8F0",
              }}
            >
              {orderTabs.files}
            </Tab>
          </TabList>
          <TabIndicator
            mt="-1.5px"
            height="2px"
            bg="blue.500"
            borderRadius="1px"
          />
          <TabPanels>
            <ClinicInfo id={order.id} isOkToClinic={isOkToClinic} />
            <OrderInfo isOkToClinic={isOkToClinic} id={order.id} tree={tree} />

            <Files order={order} />
            {/* <Notes /> */}
          </TabPanels>
        </Tabs>
      </CardBody>
      <CardFooter>{user.role === "Admin" && <HistoryOfEdit />}</CardFooter>
    </Card>
  );
}

export default OrderPage;
