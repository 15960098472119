import {
  useColorMode,
  Flex,
  Text,
  Spacer,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import { IoMdClose } from "react-icons/io";
import React from "react";
import { GiBugleCall } from "react-icons/gi";
import { useDispatch, useSelector } from "react-redux";
import { deleteAd } from "../features/Ad";
import BtnLoading from "./BtnLoading";
function Anouncement() {
  const { colorMode } = useColorMode();
  const { theme, btnColorScheme, shadeColor, mainColor } = useSelector(
    (store) => store.theme
  );
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { ad, id, isLoading } = useSelector((store) => store.ad);
  if (isLoading) return <BtnLoading />;
  if (ad === undefined) return <></>;
  return (
    <Flex
      h={16}
      // border={"1px solid red"}
      gap={"2rem"}
      bgColor={colorMode === "dark" ? "#635E6C" : "white"}
      paddingX={"1rem"}
      rounded={"md"}
      alignItems={"center"}
    >
      <Icon minW={"2rem"}>
        <GiBugleCall size={"1.5rem"} color={btnColorScheme[theme]} />
      </Icon>
      <Text
        fontSize={".9rem"}
        letterSpacing={1.1}
        // color={colorMode === "dark" ? "white" : "purple.600"}
        fontWeight={"semibold"}
      >
        {ad}
      </Text>
      <Spacer></Spacer>
      {user.role === "Admin" && (
        <IconButton
          onClick={() => {
            dispatch(deleteAd(id));
          }}
          icon={<IoMdClose color="red" size={"1.5rem"} cursor={"pointer"} />}
        />
      )}
    </Flex>
  );
}

export default Anouncement;
