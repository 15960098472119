import {
  IconButton,
  Input,
  InputGroup,
  SimpleGrid,
  InputRightElement,
  Text,
  Heading,
  GridItem,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useRef } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { Search2Icon } from "@chakra-ui/icons";
import { applyFilters } from "../features/user/AllClinicsSlice";
import { applyFilters as applyFilters2 } from "../features/user/AllStaffSlice";
import AllClinics from "./AllClinics";
import AllStaff from "./AllStaff";
import { useNavigate } from "react-router-dom";
import { logOut } from "../features/user/UserSlice";
let value = "";
function AllUsers() {
  const { user } = useSelector((store) => store.user);
  // const navigate = useNavigate();

  const searchinput = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  const location = useLocation();
  let Role = location.state?.Role;
  const { userText, heading, role } = useSelector((store) => store.lang);
  const { mainColor, theme } = useSelector((store) => store.theme);

  // if (Role === null || Role === undefined) navigate("/404");
  useEffect(() => {
    if (searchinput && searchinput.current) searchinput.current.value = "";
    if (Role === "clinic") dispatch(applyFilters({ search: "" }));
    else if (Role !== undefined) dispatch(applyFilters2({ search: "" }));
  }, [Role]);
  if (Role === undefined || Role === null || user.role === "Clinic")
    return <Navigate to={"/404"} />;
  const avatarCols = Role === "clinic" ? 2 : 2,
    userNameCols = Role === "clinic" ? 3 : 3,
    countryCols = Role === "clinic" ? 2 : 2,
    activateCols = Role === "clinic" ? 2 : 2,
    btnCols = Role === "clinic" ? 2 : 2,
    roleColss = Role === "clinic" ? 2 : 2;
  return (
    <>
      <InputGroup mb={"2rem"}>
        <Input
          placeholder={userText.searchForUser}
          ref={searchinput}
          // onChange={handler}
        />
        <InputRightElement>
          <IconButton
            icon={<Search2Icon />}
            onClick={() => {
              value = searchinput.current.value;
              if (Role === "clinic") dispatch(applyFilters({ search: value }));
              else dispatch(applyFilters2({ search: value }));
            }}
          ></IconButton>
        </InputRightElement>
      </InputGroup>
      <SimpleGrid
        columns={11}
        bgColor={mainColor[theme]}
        color="white"
        paddingX={"1rem"}
        py={"1rem"}
        mb={"2rem"}
        // justifyContent={"space-between"}
        alignItems={"center"}
        // w={"full"}
        rounded={"md"}
      >
        <GridItem colSpan={avatarCols}>
          <Heading fontSize={"md"}>{userText.avatar} </Heading>
        </GridItem>
        {Role !== "clinic" && (
          <GridItem colSpan={roleColss}>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              {role.role}
            </Text>
          </GridItem>
        )}
        <GridItem colSpan={userNameCols}>
          <Heading fontSize={"md"} fontWeight={500} fontFamily={"body"}>
            {userText.username}
          </Heading>
        </GridItem>
        {Role === "clinic" && (
          <GridItem colSpan={countryCols}>
            <Text fontSize={"md"} fontWeight={"semibold"}>
              {userText.country}
            </Text>
          </GridItem>
        )}
        <GridItem colSpan={activateCols}>
          <Heading
            fontSize={"md"}
            fontWeight={500}
            // px={"1rem"}
            fontFamily={"body"}
          >
            {heading.activation}
          </Heading>
        </GridItem>
        <GridItem colSpan={btnCols}>
          <Heading
            fontSize={"md"}
            fontWeight={500}
            // px={"1rem"}
            fontFamily={"body"}
          >
            {userText.moreInfo}
          </Heading>
        </GridItem>
      </SimpleGrid>

      {/* <SimpleGrid columns={10}> */}
      {Role === "clinic" ? <AllClinics /> : <AllStaff />}
      {/* </SimpleGrid> */}
    </>
  );
}

export default AllUsers;
