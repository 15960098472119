import React from "react";
import Chart from "chart.js/auto";
import { Doughnut } from "react-chartjs-2";

function PieCharts({ statuesData, colorMode }) {
  return (
    <Doughnut
      data={statuesData}
      options={{
        plugins: {
          legend: {
            position: "bottom",
            labels: {
              color: colorMode === "dark" ? "white" : "black",
              padding: 20,
            },
          },
          title: {
            display: true,
            color: colorMode === "dark" ? "white" : "black",
            text: "Orders States",
            padding: {
              top: 10,
              bottom: 30,
            },
          },
        },
      }}
    />
  );
}

export default PieCharts;
