import React, { useState, useEffect } from "react";
import { updateOrder, updateProduct } from "../../features/order/orderSlice";
import { Box, Flex, Button, Text } from "@chakra-ui/react";
import { Select } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  castMyUpdatedData,
  castUpdatedData,
} from "../../features/order/orderSlice";
import { buildTheUis } from "../../features/products/ProductSlice";

import QuestionsWithoutInput from "../product/QuestionsWithoutInput";
import { validateTheProducts } from "../../utils/Tools";
let options = [];
function EditProducts({ id, close }) {
  const dispatch = useDispatch();
  const { buttonsText, language } = useSelector((store) => store.lang);
  const { user } = useSelector((store) => store.user);
  const { uis, products, orderAnswers, orderQuestions } = useSelector(
    (store) => store.products
  );

  useEffect(() => {
    dispatch(castMyUpdatedData());
    dispatch(castUpdatedData());
  }, [dispatch]);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  let { updatedData, myUpdatedData } = useSelector((store) => store.order);
  let order =
    user.role === "Clinic"
      ? Object.assign({}, myUpdatedData)
      : Object.assign({}, updatedData);
  const [values, setValues] = useState([]);
  const cancle = () => {
    setValues(order.order.ids);
    close(false);
  };
  const submitHandler = () => {
    if (values.length < 1) return;
    // console.log(values);
    const ourProducts = products.filter((e) => {
      return values.includes(e.id);
    });
    const ourAnswers = [...orderAnswers];
    const ourQuestions = [...orderQuestions];
    if (validateTheProducts(ourProducts, ourAnswers, ourQuestions) === false) {
      return;
    }

    const data = { id: id, order: Object.assign({}, order) };
    // Make a deep copy of the order object and its nested properties
    data.order = JSON.parse(JSON.stringify(order));

    // Update the patient products
    data.order.order.ids = values;
    data.order.order.answerIds = [...orderAnswers];

    // Optionally, update the history or other properties if needed
    if (user.role !== "Clinic") data.order.history.updateDescription = note;

    dispatch(updateOrder(data));
    const newProducts = products.filter((item) => values.includes(item.id));
    dispatch(
      updateProduct({
        products: newProducts.map((item) => {
          return {
            product: {
              id: item.id,
              name: language === "English" ? item.name.en : item.name.de,
            },
          };
        }),
        description: note,
      })
    );
  };
  const choiceProducts = (item) => {
    dispatch(buildTheUis({ data: item }));

    setValues(item);
  };

  const note = `Change the products of the order`;

  options = products
    .filter((e) => {
      return e.isActive === true;
    })
    .map((item) => {
      return {
        label: language === "English" ? item.name.en : item.name.de,
        value: item.id,
      };
    });
  // console.log(products);
  return (
    <Flex flexDir={"column"}>
      <Flex gap={"1rem"}>
        {" "}
        <Select
          mode="multiple"
          style={{
            width: "100%",
          }}
          defaultValue={values}
          // placeholder="Tags Mode"
          onChange={choiceProducts}
          options={options}
        />
        <Flex gap={"1rem"}>
          <Button onClick={cancle}>{buttonsText.cancel}</Button>
          <Button colorScheme={btnColorScheme[theme]} onClick={submitHandler}>
            {buttonsText.save}
          </Button>
        </Flex>
      </Flex>
      {uis.map((ui) => {
        return (
          <Flex
            marginTop={"1rem"}
            alignSelf={"start"}
            rounded={"md"}
            width={"100%"}
            flexDir={"column"}
            outline={"1px solid gray"}
            alignItems={"start"}
          >
            <Box p={"1rem"}>
              <Text>{ui.name.en}</Text>
            </Box>
            {ui?.data?.map((item, index) => {
              return (
                <QuestionsWithoutInput
                  item={item}
                  key={index}
                  num={`Q${index + 1}`}
                />
              );
            })}
          </Flex>
        );
      })}
    </Flex>
  );
}

export default EditProducts;
