import React, { useEffect } from "react";
import { Button, Result } from "antd";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setActivePage } from "../features/chat/chatSlice";
function Error() {
	const { error, buttonsText } = useSelector((store) => store.lang);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setActivePage(100));
	}, []);
	return (
		<Result
			status="404"
			title="404"
			subTitle={error.notFound}
			extra={
				<Link to={"/"}>
					<Button type="primary">{buttonsText.backButton}</Button>
				</Link>
			}
		/>
	);
}

export default Error;
