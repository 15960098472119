import React from "react";
import { useState, useEffect } from "react";
import { validate } from "../utils/validation";
import {
	Box,
	Button,
	DrawerFooter,
	DrawerHeader,
	// Textarea,
	FormLabel,
	Select,
	Input,
	InputGroup,
	Stack,
	DrawerBody,
	DrawerCloseButton,
	DrawerContent,
	DrawerOverlay,
	Drawer,
	FormControl,
	InputRightElement,
	useToast,
	FormHelperText,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { createUser } from "../features/user/UserSlice";
import { logOut } from "../features/user/UserSlice";
import { BASEURL } from "../utils/urls";
import { useNavigate } from "react-router-dom";
const initialState = {
	userName: "",
	name: "",
	email: "",
	password: "",
	Role: "Staff",
	phone: "",
	country: "",
	location: "",
	gender: true,
	doctorName: "",
	dateOfBirth: "1/1/2000",
};

function CreateUser({ isOpen, onClose }) {
	const navigate = useNavigate();
	const { user } = useSelector((store) => store.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined) dispatch(logOut());
	}, [user]);
	const { theme, btnColorScheme } = useSelector((store) => store.theme);
	const [showPassword, setShowPassword] = useState(false);
	const [values, setValues] = useState(initialState);
	const handler = (e) => {
		const name = e.target.name,
			value = e.target.value;
		setValues({ ...values, [name]: value });
	};
	const toast = useToast();
	const submitHandler = async (e) => {
		e.preventDefault();

		if (values.Role === "clinic") {
			const clinic = {
				userName: "",
				name: "",
				email: "",
				password: "",
				role: "",
				phone: "",
				doctorName: "",
				country: "",
				location: "",
			};
			Object.keys(clinic).forEach((key) => {
				clinic[key] = values[key];
			});
			const { respond, msg } = validate(clinic);
			toast({
				title: respond,
				description: msg,
				status: respond,
				duration: 3000,
				isClosable: true,
			});
			if (respond === "error") {
				return;
			}
			clinic.userName = clinic.userName;
			try {
				await dispatch(
					createUser({
						url: `${BASEURL}/api/clinic/addclinic`,
						user: clinic,
						token: user.token,
					})
				).then((e) => {
					if (e && e.payload && e.payload.respond === "error") {
						const flag = e.payload.state;
						if (flag === "403" || flag === "401") navigate("/403");
						else if (flag === "400") navigate("/faild");
						else if (flag === "404") navigate("/404");
						else if (flag === "500") navigate("/500");
					} else navigate("/success");
				});
			} catch (error) {
				navigate("/");
			}
		} else {
			const staff = {
				userName: "",
				name: "",
				password: "",
				role: "",
				gender: true,
				dateOfBirth: "1/1/2000",
			};
			Object.keys(staff).forEach((key) => {
				if (key === "role") {
					staff[key] = values["Role"];
				} else staff[key] = values[key];
			});
			// staff.role = values["Role"];
			const { respond, msg } = validate(staff);
			toast({
				title: respond,
				description: msg,
				status: respond,
				duration: 2000,
				isClosable: true,
			});
			if (respond === "error") {
				return;
			}

			try {
				await dispatch(
					createUser({
						url: `${BASEURL}/api/staff/addstaff`,
						user: staff,
						token: user.token,
					})
				).then((e) => {
					if (e && e.payload && e.payload.respond === "error") {
						const flag = e.payload.state;
						if (flag === "403" || flag === "401") navigate("/403");
						else if (flag === "400") navigate("/faild");
						else if (flag === "404") navigate("/404");
						else if (flag === "500") navigate("/500");
					} else navigate("/success");
				});
			} catch (error) {
				navigate("/");
			}
		}
	};
	const { buttonsText, updateUser, gender, roleText, heading } = useSelector(
		(store) => store.lang
	);
	return (
		<Drawer isOpen={isOpen} size={"md"} placement="right" onClose={onClose}>
			<DrawerOverlay />
			<DrawerContent>
				<DrawerCloseButton />
				<DrawerHeader borderBottomWidth="1px">
					{heading.createANewAccount}
				</DrawerHeader>

				<DrawerBody>
					<Stack spacing="24px">
						<Box>
							<FormControl id="username" isRequired>
								<FormLabel htmlFor="username">
									{updateUser.username}
								</FormLabel>
								<InputGroup>
									<Input
										required={true}
										name="userName"
										onChange={handler}
										id="username"
										placeholder={updateUser.username}
									/>
								</InputGroup>
								<FormHelperText>
									{heading.nameHint}
								</FormHelperText>
							</FormControl>
						</Box>
						<Box>
							<FormControl id="name" isRequired>
								<FormLabel htmlFor="name">
									{updateUser.name}
								</FormLabel>
								<Input
									name="name"
									onChange={handler}
									id="name"
									placeholder={updateUser.name}
								/>
								<FormHelperText>
									{heading.nameHint}
								</FormHelperText>
							</FormControl>
						</Box>

						{values?.Role === "clinic" && (
							<>
								<Box>
									<FormControl id="email" isRequired>
										<FormLabel htmlFor="email">
											{updateUser.email}
										</FormLabel>
										<InputGroup>
											<Input
												onChange={handler}
												name="email"
												type="email"
												id="email"
												placeholder={updateUser.email}
											/>
										</InputGroup>
									</FormControl>
								</Box>
								<Box>
									<FormControl id="phone" isRequired>
										<FormLabel htmlFor="phone">
											{updateUser.phone}
										</FormLabel>
										<InputGroup>
											<Input
												onChange={handler}
												name="phone"
												type="text"
												id="phone"
												placeholder={updateUser.phone}
											/>
										</InputGroup>
									</FormControl>
								</Box>
							</>
						)}
						<Box>
							<FormControl id="password" isRequired>
								<FormLabel>{updateUser.password}</FormLabel>
								<InputGroup>
									<Input
										name="password"
										onChange={handler}
										placeholder={updateUser.password}
										type={
											showPassword ? "text" : "password"
										}
									/>
									<InputRightElement h={"full"}>
										<Button
											variant={"ghost"}
											onClick={() =>
												setShowPassword(
													(showPassword) =>
														!showPassword
												)
											}
										>
											{showPassword ? (
												<ViewIcon />
											) : (
												<ViewOffIcon />
											)}
										</Button>
									</InputRightElement>
								</InputGroup>
								<FormHelperText>
									{heading.passwordHint}
								</FormHelperText>
							</FormControl>
						</Box>

						<Box>
							<FormControl id="owner" isRequired>
								<FormLabel htmlFor="owner">
									{heading.selectType}
								</FormLabel>
								<Select
									name="Role"
									id="owner"
									onChange={handler}
								>
									<option value="staff">
										{roleText.staff}
									</option>
									<option value="admin">
										{roleText.admin}
									</option>
									<option value="clinic">
										{roleText.clinic}
									</option>
								</Select>
							</FormControl>
						</Box>
						{values?.Role === "clinic" && (
							<Box>
								<FormControl id="doctorName" isRequired>
									<FormLabel htmlFor="doctorName">
										{updateUser.drName}
									</FormLabel>
									<Input
										onChange={handler}
										name="doctorName"
										id="doctorName"
										placeholder={updateUser.drName}
									/>
								</FormControl>
							</Box>
						)}
						{values?.Role === "clinic" && (
							<Box>
								<FormControl id="Country" isRequired>
									<FormLabel htmlFor="Country">
										{updateUser.country}
									</FormLabel>
									<Input
										onChange={handler}
										name="country"
										id="Country"
										placeholder={updateUser.country}
									/>
								</FormControl>
							</Box>
						)}
						{values?.Role === "clinic" && (
							<Box>
								<FormControl id="adress" isRequired>
									<FormLabel htmlFor="adress">
										{updateUser.location}
									</FormLabel>
									<Input
										onChange={handler}
										name="location"
										id="adress"
										placeholder={updateUser.location}
									/>
								</FormControl>
							</Box>
						)}
						{values?.Role !== "clinic" && (
							<Box>
								<FormControl id="gender" isRequired>
									<FormLabel htmlFor="gender">
										{updateUser.gender}
									</FormLabel>
									<Select
										name="gender"
										id="gender"
										onChange={handler}
									>
										<option value={true}>
											{gender.male}
										</option>
										<option value={false}>
											{gender.female}
										</option>
									</Select>
								</FormControl>
							</Box>
						)}
					</Stack>
				</DrawerBody>

				<DrawerFooter borderTopWidth="1px">
					<Button variant="outline" mr={3} onClick={onClose}>
						{buttonsText.cancel}
					</Button>
					<Button
						type="submit"
						onClick={submitHandler}
						colorScheme={btnColorScheme[theme]}
					>
						{buttonsText.submit}
					</Button>
				</DrawerFooter>
			</DrawerContent>
		</Drawer>
	);
}

export default CreateUser;
