import { Flex, useColorMode } from "@chakra-ui/react";
import React, { useRef, useEffect } from "react";
import Message from "./Message";
import img from "../../assets/images/chat.jpg";
import { useSelector } from "react-redux";
import { IoCheckmarkOutline, IoCheckmarkDoneOutline } from "react-icons/io5";
// let isOpen = false;
function MessageList({ messages, see }) {
	const user = useSelector((store) => store.user.user);
	const sender = { Admin: 1, Staff: 3, Clinic: 2 };
	function formatDate(inputDate) {
		const date = new Date(inputDate);

		const options = {
			month: "numeric",
			day: "numeric",
			year: "numeric",
			hour: "numeric",
			minute: "numeric",
			hour12: true,
		};
		// let utcTime = new Date(inputDate);
		// let timezoneOffset = new Date().getTimezoneOffset();

		// Convert UTC time to local time
		// let localTime = new Date(
		// utcTime.getTime() - timezoneOffset * 60 * 1000
		// );
		// console.log(timezoneOffset);

		// const dateObject = new Date(localTime);
		// console.log(dateObject);
		const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
			// dateObject
			date
		);

		return formattedDate;
	}

	const messagesListRef = useRef(null);
	const { colorMode } = useColorMode();

	useEffect(() => {
		// console.log("scroll bottom:" + messagesListRef);
		// Scroll to the bottom when component mounts or messages change
		if (messagesListRef.current) {
			// messagesListRef.current.scrollTop =
			// 	messagesListRef.current.scrollHeight;
			const { scrollHeight, clientHeight } = messagesListRef.current;
			messagesListRef.current.scrollTo({
				left: 0,
				top: scrollHeight - clientHeight,
				behavior: "smooth",
			});
		}
	}, [messages]);
	return (
		<Flex
			ref={messagesListRef}
			my={"1rem"}
			p={"1rem"}
			// bgColor={"#ededed"}
			overflow={"scroll"}
			outline={"1px groove #ededed"}
			h={"50vh"}
			direction={"column"}
			bgImage={colorMode === "dark" ? "" : img}
			bgRepeat={"repeat"}
			//rounded={"sm"}
			w={"100%"}
		>
			{messages.map((item, index) => {
				return (
					<Message
						userName={item.senderUsername}
						key={index}
						sender={
							(sender[item.senderRole] + sender[user.role]) %
								2 ===
							0
						}
						message={item["content"]}
						time={formatDate(item.time)}
						icon={
							item.senderRole === user.role ? (
								item.isViewed ? (
									<IoCheckmarkDoneOutline
										style={{
											marginLeft: "0.2rem",
											color: "#0088cc",
										}}
									/>
								) : (
									<IoCheckmarkOutline
										style={{ marginLeft: "0.2rem" }}
									/>
								)
							) : (
								""
							)
						}
					/>
				);
			})}
		</Flex>
	);
}

export default MessageList;
