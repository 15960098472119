import React, { useEffect } from "react";
import NoneEditableBox from "../components/NoneEditableBox";
import EditableBox from "../components/EditableBox";
import PasswordChanger from "../components/PasswordChanger";
import { Link, Navigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@chakra-ui/react";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";

function UpdateUserPage() {
	const location = useLocation();
	let user = location.state?.user;
	const { role: myRole } = useSelector((store) => store.user.user);
	const { theme, btnColorScheme } = useSelector((store) => store.theme);
	const dispatch = useDispatch();
	const { user: me } = useSelector((store) => store.user);
	useEffect(() => {
		if (me === undefined) dispatch(logOut());
	}, [me]);

	useEffect(() => {
		dispatch(setActivePage(100));
	}, []);
	const { updateUser, gender, sideBar } = useSelector((store) => store.lang);
	let is_admin = myRole === "Admin";
	let Role = user?.role;
	if (user === undefined || myRole === "Clinic")
		return <Navigate to={"/404"} />;
	return (
		<>
			<NoneEditableBox
				title={updateUser.username}
				value={user.username}
			/>

			{is_admin ? (
				<EditableBox
					user={user}
					info={{ value: user.name, title: updateUser.name }}
				/>
			) : (
				<NoneEditableBox title={updateUser.name} value={user.name} />
			)}
			{Role === "Clinic" && (
				<NoneEditableBox
					title={updateUser.country}
					value={user.country}
				/>
			)}
			{Role === "Clinic" && (
				<NoneEditableBox
					title={updateUser.location}
					value={user.location}
				/>
			)}
			{Role === "Clinic" &&
				(is_admin ? (
					<EditableBox
						user={user}
						info={{ value: user.email, title: updateUser.email }}
					/>
				) : (
					<NoneEditableBox
						value={user.email}
						title={updateUser.email}
					/>
				))}
			{Role === "Clinic" &&
				(is_admin ? (
					<EditableBox
						user={user}
						info={{ value: user.phone, title: updateUser.phone }}
					/>
				) : (
					<NoneEditableBox
						value={user.phone}
						title={updateUser.phone}
					/>
				))}

			{Role === "Clinic" &&
				(is_admin ? (
					<EditableBox
						user={user}
						info={{
							title: updateUser.drName,
							value: user.doctorName,
						}}
					/>
				) : (
					<NoneEditableBox
						title={updateUser.drName}
						value={user.doctorName}
					/>
				))}
			{
				Role === "Clinic" || (
					<NoneEditableBox
						title={updateUser.gender}
						value={user.gender ? gender.male : gender.female}
					/>
				)
				// ((
			}
			{is_admin && <PasswordChanger info={user} />}
			{is_admin && Role === "Clinic" && (
				<Link
					to={"/add-order"}
					state={{ is_ok: true, clinicInfo: user }}
				>
					<Button
						w={"100%"}
						padding={"1rem"}
						textAlign={"center"}
						colorScheme={btnColorScheme[theme]}
					>
						{sideBar.addOrder}
					</Button>
				</Link>
			)}
		</>
	);
}

export default UpdateUserPage;
