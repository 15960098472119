import React from "react";
import { Tr, Th } from "@chakra-ui/react";
function TableRow({ data }) {
	// Parse the original date string
	const parsedDate = new Date(data.chanagedAt);

	// Create a formatter with the desired format
	const dateFormatter = new Intl.DateTimeFormat("en-US", {
		year: "numeric",
		month: "2-digit",
		day: "2-digit",
		hour: "numeric",
		minute: "numeric",
		hour12: true,
	});
	return (
		<Tr>
			<Th>{data.staff.username}</Th>
			<Th>{dateFormatter.format(parsedDate)}</Th>
			<Th>{data.updateDescription}</Th>
		</Tr>
	);
}

export default TableRow;
