import React, { useEffect, useState } from "react";
import ChatControle from "./ChatControle";
import MessageList from "./MessageList";
import { Box, Button, Heading, Text } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
	getMessages,
	removeData,
	setActivePage,
	setId,
} from "../../features/chat/chatSlice";
import useSignalr from "../../utils/signalRServices";
import { Link, Navigate, useLocation } from "react-router-dom";
import Loading from "../Loading";
import { logOut } from "../../features/user/UserSlice";

function Chat({ connection }) {
	// const { chatId, clinic } = useSelector((store) => store.order.order);
	const location = useLocation();
	const myChat = location?.state?.chatId;
	const clinic = location?.state?.clinicId;
	// const user = useSelector((store) => store.user.user);
	// const sender = { Admin: 1, Staff: 3, Clinic: 2 };
	// console.log(myChat);
	let {
		messages: curMsgs,
		title,
		orderId,
		isLoading,

		isTyping,
	} = useSelector((store) => store.chat);
	const { user } = useSelector((store) => store.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined) dispatch(logOut());
	}, [user]);

	useEffect(() => {
		dispatch(setActivePage(5));
		if (myChat === null || myChat === undefined) {
			return;
		}
		// console.log(myChat, clinic);

		// dispatch(setId(myChat));

		dispatch(removeData());
		dispatch(setId(myChat));

		dispatch(getMessages({ chatId: myChat, clinic }));
	}, [myChat]);
	// useEffect(() => {
	// 	see(myChat, clinic);
	// }, []);
	const { heading } = useSelector((store) => store.lang);
	let [typing, setTyping] = useState("");
	useEffect(() => {
		if (isTyping) setTyping(heading.typing);
		else setTyping("");
	}, [isTyping]);
	//signal r
	// const connection = useSignalr();

	const sendMessage = async (content) => {
		try {
			await connection.invoke(
				"SendMessage",
				myChat,
				content,
				clinic,
				title
			);
		} catch (e) {
			// console.log(e);
		}
	};
	const type = async (typing) => {
		try {
			// console.log(myChat, clinic);
			await connection.invoke("Typing", myChat, typing, clinic);
		} catch (e) {
			// console.log(e);
		}
	};
	const see = async () => {
		try {
			// console.log(myChat, clinic);
			await connection.invoke("Viewing", myChat, clinic);
		} catch (e) {
			// console.log(e);
		}
	};

	let [msgs, setMsgs] = useState(curMsgs);

	useEffect(() => {
		if (curMsgs.length > 0) {
			if (
				curMsgs[curMsgs.length - 1].owner === 1 &&
				curMsgs[curMsgs.length - 1].isViewed === false
			) {
				see();

				// 		// console.log(curMsgs, msgs);
				// 		see(myChat, clinic);
				// 		dispatch(updateMessages(myChat));
				// 		setMsgs(
				// 			curMsgs.map((item) => {
				// 				let temp = { ...item };
				// 				temp.isViewed = true;
				// 				// console.log(temp);
				// 				return temp;
				// 			})
				// 		);
			}
		}
		setMsgs(curMsgs);
		// console.log(curMsgs);
	}, [curMsgs]);
	if (isLoading) return <Loading></Loading>;
	if (myChat === undefined || myChat === null)
		return <Navigate to={"error"} />;
	return (
		<Box border="1px solid #EDEDED" p={"1rem"}>
			<Link to={"/order-info"} state={{ id: orderId }}>
				<Button
					variant={"link"}
					fontSize={"1.4rem"}
					w={"80%"}
					isTruncated
					textOverflow={"ellipsis"}
					colorScheme={"blue"}
					justifyContent={"start"}
				>
					{title}
				</Button>
			</Link>
			<Text p={".6rem"} color={"green.300"}>
				{typing}
			</Text>
			<MessageList messages={msgs} see={see} />

			<ChatControle
				type={type}
				sendMessage={sendMessage}
				// see={see}

				// sendMessageFromStaff={sendMessageFromStaff}
				// sendMessageFromClinic={sendMessageFromClinic}
				// TypingStaff={TypingStaff}
				// TypingClinic={TypingClinic}
			/>
		</Box>
	);
}

export default Chat;
