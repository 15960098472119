import React, { useState } from "react";
import {
	Flex,
	Button,
	Grid,
	GridItem,
	Text,
	Input,
	useColorMode,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
	changeClinicPassword,
	changeUserPassword,
} from "../features/user/UserSlice";
import { useDispatch, useSelector } from "react-redux";
function PasswordChanger({ info }) {
	const { colorMode } = useColorMode();
	const dispatch = useDispatch();
	let [value, setValue] = useState("");
	const [editable, setEditable] = useState(false);
	const [showPassword, setShowPassword] = useState(false);
	const changeHandler = (e) => {
		setValue(e.target.value);
	};
	const revert = () => {
		setEditable(editable === false);
	};
	const submitHandler = () => {
		const user = { password: value, userId: info.userId };
		if (info.role === "Clinic") dispatch(changeClinicPassword(user));
		else dispatch(changeUserPassword(user));
		revert();
	};
	const cancle = () => {
		value = "";
		revert();
	};
	const { buttonsText, updateUser } = useSelector((store) => store.lang);
	return (
		<Grid
			templateColumns={"repeat(7,1fr)"}
			p={"1rem"}
			borderBottom={"2px solid"}
			borderColor="gray.100"
		>
			<GridItem colSpan={2}>
				<Text
					fontSize={"1rem"}
					color={colorMode === "dark" ? "gray.100" : "gray.500"}
					fontWeight={"semibold"}
				>
					{updateUser.password} :
				</Text>
			</GridItem>
			<GridItem colSpan={3}>
				{editable ? (
					<InputGroup>
						<Input
							name="password"
							onChange={changeHandler}
							placeholder={updateUser.password}
							type={showPassword ? "text" : "password"}
						/>
						<InputRightElement h={"full"}>
							<Button
								variant={"ghost"}
								onClick={() =>
									setShowPassword(
										(showPassword) => !showPassword
									)
								}
							>
								{showPassword ? <ViewIcon /> : <ViewOffIcon />}
							</Button>
						</InputRightElement>
					</InputGroup>
				) : (
					<Text
						fontSize={"1rem"}
						color={colorMode === "dark" ? "gray.100" : "gray.500"}
						fontWeight={"semibold"}
					>
						{"**********"}
					</Text>
				)}
			</GridItem>
			<GridItem colSpan={2}>
				{editable ? (
					<Flex gap={"1rem"} justifyContent={"end"} fontSize={"1rem"}>
						<Button
							colorScheme={"blue"}
							fontSize={".8rem"}
							onClick={submitHandler}
						>
							{buttonsText.save}
						</Button>{" "}
						<Button onClick={cancle} fontSize={".8rem"}>
							{" "}
							{buttonsText.cancel}
						</Button>
					</Flex>
				) : (
					<Flex justifyContent={"end"}>
						<Button
							variant={"link"}
							colorScheme={"blue"}
							onClick={revert}
						>
							{buttonsText.edit}
						</Button>
					</Flex>
				)}
			</GridItem>
		</Grid>
	);
}

export default PasswordChanger;
