import React from "react";
import { Select } from "@chakra-ui/react";
// import { states, states2 } from "../utils/Tools";
import { useSelector } from "react-redux";

function FilterItem({ value, handler }) {
  const { states } = useSelector((store) => store.lang);
  return (
    <Select value={value} w={"30%"} onChange={handler}>
      <option value={"all"}>all</option>
      {states?.map((item, index) => {
        return (
          <option key={index} value={index}>
            {item}
          </option>
        );
      })}
    </Select>
  );
}

export default FilterItem;
