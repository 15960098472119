import {
  Text,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  Box,
  Center,
  SimpleGrid,
  useDisclosure,
  Checkbox,
  GridItem,
  Flex,
  IconButton,
} from "@chakra-ui/react";

import { ChevronDownIcon } from "@chakra-ui/icons";
import React, { useEffect, useState } from "react";
import Filters from "../components/Filters";
import Order from "../components/Order";
import { useColorMode } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
  changeOrdersState,
  getOrders,
  handleChange,
} from "../features/allOrders/allOrdersSlice";
import { Empty, Pagination } from "antd";
import { MdOutlineSelectAll } from "react-icons/md";

import Loading from "../components/Loading";
import HeadingTitle from "../components/order/HeadingTitle";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";
import { clearTheData } from "../features/products/ProductSlice";

const getDate = (createdDate) => {
  let utcTime = new Date(createdDate);
  let timezoneOffset = new Date().getTimezoneOffset();

  // Convert UTC time to local time
  let localTime = new Date(utcTime.getTime() - timezoneOffset * 60 * 1000);
  const dateObject = new Date(localTime);

  // Format the date as "DD/MM/YYYY"
  const formattedDate = dateObject.toLocaleDateString("en-GB");
  return formattedDate;
};
// let CheckedOrderes = new Object();
function AllOrders() {
  const [CheckedOrderes, setCheckedOrderes] = useState({ orders: [], cnt: 0 }); // Initialize with an empty object
  // const [cnt, setCnt] = useState(CheckedOrderes.length);
  const [shippingPP, setShippingPP] = useState(false);

  const handleCheckOrder = (e, orderId, clinicId) => {
    if (e.target.checked === false) {
      let updatedOrders = CheckedOrderes.orders.filter((order) => {
        return order.orderId != orderId;
      });
      setCheckedOrderes({ orders: updatedOrders, cnt: updatedOrders.length });
    } else {
      setCheckedOrderes((prevState) => ({
        orders: [
          ...prevState.orders,
          {
            orderId,
            clinicId,
            orderHistoryRequest: {
              updateDescription: `Changes the state from ${
                states[e.state]
              } to ${states[4]}`,
            },
          },
        ],
        cnt: prevState.cnt + 1,
      }));
    }
  };
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { states, orderLabels } = useSelector((store) => store.lang);
  const { theme, btnColorScheme, mainColor } = useSelector(
    (store) => store.theme
  );
  function doNothing() {}
  const {
    pages,
    productId,
    orders,
    exportedOrders,
    isLoading,
    state,
    search,
    startDate,
    endDate,
    sortedAsc,
    priority,
    itemPerPage,
    updateIsLoading,
  } = useSelector((store) => store.orders);
  const { orderType } = useSelector((store) => store.lang);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemPerPage); // Default page size

  const handlePageChange = (page) => {
    setCurrentPage(page);
    setShippingPP(false);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    setShippingPP(false);
    dispatch(handleChange({ name: "itemPerPage", value: size }));
    setCurrentPage(1); // Reset to the first page when the page size changes
  };

  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);

  useEffect(() => {
    dispatch(setActivePage(2));
    dispatch(clearTheData());
  }, []);
  useEffect(() => {
    if (updateIsLoading === false)
      dispatch(
        getOrders({
          page: currentPage,
          itemPerPage: pageSize,
          state: "all",
        })
      );
  }, [
    updateIsLoading,
    search,
    state,
    startDate,
    endDate,
    sortedAsc,
    currentPage,
    pageSize,
    productId,
    priority,
  ]);

  const { allOrders, buttonsText, empty } = useSelector((store) => store.lang);
  const { colorMode } = useColorMode();
  let items =
    orders === undefined || orders.length === 0
      ? []
      : orders?.orders?.map((item, index) => {
          let bcolor = `${
            index % 2 === 0
              ? `${colorMode === "dark" ? "gray.700" : "gray.50"}`
              : `${colorMode === "dark" ? "gray.800" : "gray.100"}`
          }`;

          return (
            <Order
              handler={handleCheckOrder}
              CheckboxIsVisible={shippingPP && item.state === 3}
              key={index}
              bcolor={bcolor}
              order={item}
            ></Order>
          );
        });
  // const doc = new jsPDF();

  if (isLoading || updateIsLoading) return <Loading />;
  const clinicCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    fnameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    snameCols = user.role === "Clinic" ? 2 : [0, 0, 2, 2],
    recordCols = user.role === "Clinic" ? 2 : 2,
    statesCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    dateCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2],
    productsCols = user.role === "Clinic" ? 2 : [3, 3, 2, 2];

  const clinicDisplay = user.role === "Clinic" ? "none" : "block",
    fnameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    snameDisplay =
      user.role === "Clinic" ? "block" : ["none", "none", "block", "block"],
    recordDisplay = user.role === "Clinic" ? "block" : "block",
    statesDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"],
    dateDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"],
    productsDisplay =
      user.role === "Clinic" ? "block" : ["block", "block", "block", "block"];

  return (
    <>
      <Filters />

      <SimpleGrid
        columns={user.role === "Clinic" ? 12 : 15}
        alignItems={"left"}
        w={"100%"}
        gap={"1rem"}
        // my={"1rem"}
        // justifyContent={"center"}
        color={colorMode === "dark" ? "gray.100" : "gray.500"}
        mt={"1rem"}
        // display={"grid"}
      >
        {user.role !== "Clinic" && (
          <Flex w={"fit-content"} alignItems={"center"} gap={".1rem"}>
            <GridItem colSpan={1}>
              <Flex
                rounded={"md"}
                p={".4rem"}
                bgColor={"#F6F5F2"}
                alignItems={"center"}
                gap={".3rem"}
              >
                {" "}
                <Checkbox
                  size={"lg"}
                  outline={"1px solid #666"}
                  // marginEnd={".5rem"}
                  variant={"outline"}
                  // icon={<MdOutlineSelectAll />}
                  colorScheme={btnColorScheme[theme]}
                  onChange={() => {
                    if (user.role !== "Admin") return;
                    if (shippingPP === false) {
                      let checked = orders?.orders
                        ?.filter((e) => e.state === 3)
                        .map((e) => {
                          return {
                            orderId: e.id,
                            clinicId: e.clinic.id,
                            orderHistoryRequest: {
                              updateDescription: `Changes the state from ${
                                states[e.state]
                              } to ${states[4]}`,
                            },
                          };
                        });
                      setCheckedOrderes({
                        orders: checked,
                        cnt: checked ? checked.length : 0,
                      });
                    } else {
                      setCheckedOrderes({ orders: [], cnt: 0 });
                    }
                    setShippingPP(!shippingPP);
                  }}
                />
                <Menu>
                  <MenuButton>
                    <ChevronDownIcon />
                  </MenuButton>
                  <MenuList>
                    <MenuItem
                      onClick={(e) => {
                        // console.log(CheckedOrderes)
                        if (CheckedOrderes?.orders?.length > 0) {
                          dispatch(changeOrdersState(CheckedOrderes));
                          setShippingPP(false);
                        }
                      }}
                    >
                      {states[4]}
                    </MenuItem>
                    <MenuItem fontWeight={"bold"} color={"#1B99D4"}>
                      {buttonsText.totalSelected} :{CheckedOrderes.cnt}
                    </MenuItem>
                  </MenuList>
                </Menu>
              </Flex>
            </GridItem>
          </Flex>
        )}
        {user.role === "Clinic" || (
          <HeadingTitle
            value={allOrders.clinicName}
            cols={clinicCols}
            display={clinicDisplay}
          />
        )}

        <HeadingTitle
          value={allOrders.fName}
          cols={fnameCols}
          display={fnameDisplay}
        />
        <HeadingTitle
          value={allOrders.sName}
          cols={snameCols}
          display={snameDisplay}
        />
        <HeadingTitle
          value={allOrders.idNumber}
          cols={recordCols}
          display={recordDisplay}
        />
        <HeadingTitle
          value={allOrders.date}
          cols={dateCols}
          display={dateDisplay}
        />

        <HeadingTitle
          value={allOrders.state}
          cols={statesCols}
          display={statesDisplay}
        />
        <HeadingTitle
          value={allOrders.producs}
          cols={productsCols}
          display={productsDisplay}
        />
      </SimpleGrid>
      {items.length === 0 ? <Empty description={empty.empty} /> : items}

      <Center mt={"1rem"}>
        <Pagination
          current={currentPage}
          pageSize={pageSize}
          total={pageSize * pages} // Replace with the actual total number of items
          onChange={handlePageChange}
          onShowSizeChange={handleSizeChange}
          showSizeChanger
          pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
        />
      </Center>
    </>
  );
}

export default AllOrders;
