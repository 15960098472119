import React, { useState } from "react";

import {
  Box,
  Flex,
  Avatar,
  Text,
  Heading,
  Center,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  Stack,
  useColorMode,
  useDisclosure,
  VStack,
  IconButton,
  HStack,
  InputGroup,
} from "@chakra-ui/react";
import { MoonIcon, SunIcon, ChatIcon, HamburgerIcon } from "@chakra-ui/icons";
import { useDispatch, useSelector } from "react-redux";
import { logOut, uploadMyPic } from "../features/user/UserSlice";
import Sidebar from "./Sidebar";
import { PiTrash } from "react-icons/pi";
import { Link } from "react-router-dom";
import { changeLang } from "../features/language";
import Notification from "../pages/Notification";
import Cookies from "js-cookie";
import { Upload } from "antd";

import { BASEURL } from "../utils/urls";
import { FaCamera } from "react-icons/fa";
import AllChats from "../pages/AllChats";

import { Spin } from "antd";
export default function Nav({ connection }) {
  const { user, picLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();

  const { colorMode, toggleColorMode } = useColorMode();
  const { isOpen, onOpen, onClose } = useDisclosure();
  let unreadChats = useSelector((store) => store.chat.total);
  let name = user.username;
  const [file, setFile] = useState(null);

  const props = {
    onRemove: (file) => {
      setFile(null);
    },
    beforeUpload: (file) => {
      setFile(file);
      return false;
    },
    file,
  };

  const { language, mainActions } = useSelector((store) => store.lang);

  const onSubmit = () => {
    // e.preventDefault();
    if (file === null) return;

    const formData = new FormData();

    formData.append("Image", file);

    dispatch(uploadMyPic(formData));
    setFile(null);
  };
  const {
    isOpen: chatsIsOpen,
    onOpen: chatsOnOpen,
    onClose: chatsOnClose,
  } = useDisclosure();
  const closeConnection = async () => {
    try {
      await connection.stop();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <Box
        rounded={"md"}
        bg={colorMode === "dark" ? "#424242" : "white"}
        paddingX={4}
      >
        <Flex
          h={16}
          alignItems={"center"}
          justifyContent={[
            "space-between",
            "space-between",
            "space-between",
            "space-between",
          ]}
        >
          <Menu display={["block", "block", "block", "none"]}>
            <MenuButton
              display={["block", "block", "block", "none"]}
              as={IconButton}
              aria-label="Options"
              icon={<HamburgerIcon />}
              variant="outline"
            />
            <MenuList>
              <VStack display={["block", "block", "block", "none"]}>
                {" "}
                <Sidebar
                  colorMode={colorMode}
                  onOpen={onOpen}
                  isOpen={isOpen}
                  onClose={onClose}
                />
              </VStack>
            </MenuList>
          </Menu>
          <Box display={["none", "block", "none", "block"]}>
            <Center>
              <Heading color={"#A0AEC0"} fontSize={"xl"}>
                <Text
                  as={"span"}
                  color={colorMode === "dark" ? "blue.200" : "blue.600"}
                  fontWeight={"bold"}
                >
                  S
                </Text>
                olutions{" "}
                <Text
                  as={"span"}
                  color={colorMode === "dark" ? "blue.200" : "blue.600"}
                  fontWeight={"bold"}
                >
                  4 M
                </Text>
                edical{" "}
                <Text
                  as={"span"}
                  color={colorMode === "dark" ? "blue.200" : "blue.600"}
                  fontWeight={"bold"}
                >
                  P
                </Text>
                ractices
              </Heading>
            </Center>
          </Box>

          <Flex alignItems={"center"}>
            <Stack direction={"row"} spacing={7}>
              <HStack
                justifyContent={"space-evenly"}
                display={["none", "none", "block", "block"]}
              >
                {user.role === "Admin" && (
                  <Link to={"/Trash"}>
                    <Button mx={"1rem"}>
                      <PiTrash />
                    </Button>
                  </Link>
                )}
                <Button
                  mx={"1rem"}
                  onClick={() => {
                    dispatch(changeLang());
                    Cookies.set(
                      "lang",
                      language === "English" ? "Deutsch" : "English"
                    );
                  }}
                >
                  {language}
                </Button>
                <Button onClick={toggleColorMode} mx={"1rem"}>
                  {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                </Button>
                <Notification texts />

                <Button onClick={chatsOnOpen} mx={"1rem"} position={"relative"}>
                  {<ChatIcon />}
                  {unreadChats ? (
                    <Box
                      bg={"red"}
                      pos={"absolute"}
                      right={"0"}
                      top={"0"}
                      fontSize={"md"}
                      rounded={"50%"}
                      width={"1rem"}
                      height={"1rem"}
                    ></Box>
                  ) : (
                    ""
                  )}
                </Button>
              </HStack>
              <Menu>
                <MenuButton
                  as={Button}
                  rounded={"full"}
                  variant={"link"}
                  cursor={"pointer"}
                  minW={0}
                >
                  {picLoading ? (
                    <Spin />
                  ) : (
                    <Avatar
                      src={`${BASEURL}/api/veHFSaSyDihimAicqjtcpYuPwpxvdX/${user.profileImage}`}
                    />
                  )}
                </MenuButton>
                <MenuList alignItems={"center"}>
                  <br />
                  <Center>
                    {picLoading ? (
                      <Spin size="large" />
                    ) : (
                      <Avatar
                        src={`${BASEURL}/api/veHFSaSyDihimAicqjtcpYuPwpxvdX/${user.profileImage}`}
                        rounded={"full"}
                        w={"8rem"}
                        h={"8rem"}
                      />
                    )}
                  </Center>
                  <Flex
                    maxW={"250px"}
                    mt={"2rem"}
                    alignItems={"center"}
                    justifyContent={"space-around"}
                  >
                    <InputGroup size="sm" w={"25%"} overflow={"hidden"}>
                      <Upload
                        onChange={onSubmit}
                        maxCount={1}
                        {...props}
                        accept=".png,.jpg,jpeg"
                        fileList={file === null ? [] : [file]}
                      >
                        <Button colorScheme={"blue"}>
                          <FaCamera />
                        </Button>
                      </Upload>
                    </InputGroup>
                    {/* {file !== null && (
											<Button
												onClick={onSubmit}
												fontSize={".9rem"}
												variant={"link"}
												colorScheme={"blue"}
											>
												{navBar.submit}
											</Button>
										)} */}
                  </Flex>

                  <br />
                  <Center>
                    <p>{name}</p>
                  </Center>
                  <br />
                  <MenuDivider />
                  <HStack
                    my={"1rem"}
                    gap={"1rem"}
                    display={["flex", "flex", "none", "none"]}
                  >
                    {user.role === "Admin" && (
                      <Link to={"/Trash"}>
                        <Button mx={"1rem"}>
                          <PiTrash />
                        </Button>
                      </Link>
                    )}
                    <Button
                      onClick={() => {
                        dispatch(changeLang());
                        Cookies.set(
                          "lang",
                          language === "English" ? "Deutsch" : "English"
                        );
                      }}
                    >
                      {language}
                    </Button>
                    <Button
                      onClick={toggleColorMode}
                      // mx={"1rem"}
                    >
                      {colorMode === "light" ? <MoonIcon /> : <SunIcon />}
                    </Button>
                    <Notification texts />
                    <Button onClick={chatsOnOpen} position={"relative"}>
                      {<ChatIcon />}
                      {unreadChats ? (
                        <Box
                          bg={"red"}
                          pos={"absolute"}
                          right={"0"}
                          top={"0"}
                          fontSize={"md"}
                          rounded={"50%"}
                          width={"1rem"}
                          height={"1rem"}
                        />
                      ) : (
                        ""
                      )}
                    </Button>
                  </HStack>

                  <MenuItem>
                    <Button
                      colorScheme={"red"}
                      w={"100%"}
                      onClick={() => {
                        dispatch(logOut());
                        closeConnection();
                      }}
                    >
                      {mainActions.logOut}
                    </Button>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Stack>
          </Flex>
        </Flex>
      </Box>
      <AllChats
        isOpen={chatsIsOpen}
        onOpen={chatsOnOpen}
        onClose={chatsOnClose}
      />
    </>
  );
}
