import { SearchIcon } from "@chakra-ui/icons";
import moment from "moment";
import {
  Input,
  HStack,
  InputGroup,
  InputLeftElement,
  Flex,
  Button,
  AbsoluteCenter,
} from "@chakra-ui/react";

import { FaSortNumericDown, FaSortNumericUp } from "react-icons/fa";
import { getProducts } from "../features/products/ProductSlice";
import React, { useEffect, useState } from "react";
import FilterItem from "./FilterItem";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFilters,
  handleChange,
  applyFilters,
} from "../features/allOrders/allOrdersSlice";
import { DatePicker } from "antd";
import FilterProducts from "./FilterProducts";
import FilterPriority from "./FilterPriority";
const { RangePicker } = DatePicker;

function Filters() {
  const {
    state,
    search,
    sortedAsc,
    productId,
    priority: pri,
  } = useSelector((store) => store.orders);
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  const [searchWord, setSearch] = useState(search);
  const [statu, setStatu] = useState(state);
  const [priority, setPriority] = useState(pri);
  const [selectedRange, setSelectedRange] = useState([]);
  const handler = (e) => {
    setStatu(e.target.value);
  };
  const { products } = useSelector((store) => store.products);
  const [productfilter, setProduct] = useState(productId);
  const handlerProduct = (e) => {
    setProduct(e.target.value);
  };
  const handlePriority = (e) => {
    setPriority(e.target.value);
  };
  const handleChange = (dates, dateStrings) => {
    // Format the date strings to "MM/DD/YYYY" and update state
    setSelectedRange(
      dateStrings?.map((date) => moment(date).format("MM/DD/YYYY"))
    );
  };
  const [sorted, setSorted] = useState(sortedAsc);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const { buttonsText } = useSelector((store) => store.lang);
  return (
    <Flex
      direction={"column"}
      p={"1rem"}
      // boxShadow="sm"
      border={"1px solid"}
      borderColor={"gray.100"}
      gap="2rem"
    >
      <HStack>
        <InputGroup>
          <InputLeftElement>
            <SearchIcon></SearchIcon>
          </InputLeftElement>
          <Input
            onKeyDown={(e) => {
              if (e.key === "Enter" && searchWord.trim() !== "") {
                const [start, end] = selectedRange;
                dispatch(
                  applyFilters({
                    state: statu,
                    search: searchWord,
                    startDate: start === "" ? "all" : start,
                    endDate: end === "" ? "all" : end,
                    sortedAsc: sorted,
                    productId: productfilter,
                    priority: priority,
                  })
                );
              }
            }}
            value={searchWord}
            type={"search"}
            bg="ButtonFace"
            name="search"
            onChange={(e) => {
              setSearch(e.target.value);
            }}
          ></Input>
        </InputGroup>
        <FilterItem value={statu} handler={handler} />
      </HStack>

      <RangePicker
        value={selectedRange?.map((date) => moment(date, "MM/DD/YYYY"))}
        format={"MM/DD/YYYY"}
        onChange={handleChange}
      />
      <Flex width={"100%"} justifyContent={"end"}>
        <Flex gap={"2rem"} justifyContent={"end"}>
          <FilterPriority handler={handlePriority} value={priority} />
          <FilterProducts
            handler={handlerProduct}
            value={productfilter}
            products={products}
          />
          <Button
            justifySelf={"end"}
            alignSelf={"end"}
            variant={"outline"}
            position={"relative"}
            width={"fit-content"}
            minW={"6rem"}
            paddingX={".6rem"}
            fontSize={"sm"}
            // color="white"
            // bgColor={sorted ? "purple.400" : "purple.300"}
            onClick={() => setSorted(!sorted)}
          >
            {/* <AbsoluteCenter> */}
            {
              sorted ? buttonsText.oldest : buttonsText.newest
              // <FaSortNumericDown />
              // <FaSortNumericUp />
            }
            {/* </AbsoluteCenter> */}
          </Button>
        </Flex>
      </Flex>
      {/* <DatePicker /> */}
      <HStack alignSelf={"end"} gap={"1rem"}>
        <Button
          alignSelf={"end"}
          width={"fit-content"}
          colorScheme={btnColorScheme[theme]}
          paddingX={"1rem"}
          py={".1rem"}
          variant={"outline"}
          onClick={() => {
            dispatch(clearFilters());
          }}
        >
          {buttonsText.clear}
        </Button>
        <Button
          width={"fit-content"}
          colorScheme={btnColorScheme[theme]}
          paddingX={"1rem"}
          py={".1rem"}
          onClick={() => {
            const [start, end] = selectedRange;
            dispatch(
              applyFilters({
                state: statu,
                search: searchWord,
                startDate: start === "" ? "all" : start,
                endDate: end === "" ? "all" : end,
                sortedAsc: sorted,
                productId: productfilter,
                priority: priority,
              })
            );
          }}
        >
          {buttonsText.filter}
        </Button>
      </HStack>
    </Flex>
  );
}

export default Filters;
