import React, { useEffect, useState } from "react";
import {
	VStack,
	Flex,
	Input,
	Button,
	InputGroup,
	InputLeftAddon,
} from "@chakra-ui/react";
import { Navigate } from "react-router-dom";
import { setActivePage } from "../features/chat/chatSlice";
import { useDispatch, useSelector } from "react-redux";
import { deleteAd, postAd } from "../features/Ad";
import { MdOutlinePostAdd } from "react-icons/md";
import { Select } from "antd";
function AddAd() {
	const [value, setValue] = useState("");
	const [value2, setValue2] = useState("");
	const { id } = useSelector((store) => store.ad);
	const { theme, btnColorScheme } = useSelector((store) => store.theme);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch(setActivePage(100));
	}, [dispatch]);
	const handler = (e) => {
		setValue(e.target.value);
	};
	const handler2 = (e) => {
		setValue2(e.target.value);
	};
	const submitHandler = () => {
		if (value && value.trim() !== "" && value2 && value2.trim() !== "") {
			let myPromise = new Promise(function (myResolve) {
				if (id) dispatch(deleteAd(id));
				// "Producing Code" (May take some time)

				myResolve(); // when successful
				// myReject(); // when error
			});

			// "Consuming Code" (Must wait for a fulfilled Promise)
			myPromise.then(dispatch(postAd({ en: value.trim(), de: value2 })));
		}
	};
	const { user } = useSelector((store) => store.user);
	if (user.role !== "Admin") return <Navigate to={"/404"} />;
	return (
		<VStack boxShadow={"md"} p={"1rem"} alignItems={"start"} gap={"1rem"}>
			<Flex
				direction={"column"}
				justifyContent={"start"}
				gap="1rem"
				width={"100%"}
			>
				<InputGroup>
					<InputLeftAddon p={"1.5rem"}>English</InputLeftAddon>
					<Input
						onChange={handler}
						colorScheme={btnColorScheme[theme]}
						fontSize={"lg"}
						p={"1.5rem"}
						type="text"
						placeholder={""}
					></Input>
				</InputGroup>
				<InputGroup>
					<InputLeftAddon p={"1.5rem"}>Deutsch</InputLeftAddon>
					<Input
						onChange={handler2}
						colorScheme={btnColorScheme[theme]}
						fontSize={"lg"}
						p={"1.5rem"}
						type="text"
						placeholder={""}
					></Input>
				</InputGroup>
				<Button
					width={"fit-content"}
					alignSelf={"end"}
					onClick={submitHandler}
					fontSize={"xl"}
					colorScheme={btnColorScheme[theme]}
					padding={"1.5rem"}
				>
					<MdOutlinePostAdd size={"2rem"} />
				</Button>
			</Flex>
		</VStack>
	);
}

export default AddAd;
