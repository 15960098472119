import React, { useEffect, useState } from "react";
import { Center, Button } from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { Pagination } from "antd";
import UserCard from "../components/UserCard";
import Loading from "../components/Loading";
import { Empty } from "antd";
import {
	changepageStaff,
	exportStaff,
	handleChange,
} from "../features/user/AllStaffSlice";
import {
	deActivateUser,
	activateUser,
	logOut,
} from "../features/user/UserSlice";
import { setActivePage } from "../features/chat/chatSlice";
const colors = [
	"rgba(255, 99, 132, 8)",
	"rgba(255, 159, 64, 8)",
	"rgba(255, 205, 86, 8)",
	"rgba(75, 192, 192, 8)",
	"rgba(54, 162, 235, 8)",
	"rgba(153, 102, 255, 8)",
	"rgba(201, 203, 207, 8)",
];

function AllStaff() {
	const { empty } = useSelector((store) => store.lang);
	const { pages, search, staffs, isLoading, itemPerPage } = useSelector(
		(store) => store.staff
	);
	const { theme, btnColorScheme } = useSelector((store) => store.theme);
	const [currentPage, setCurrentPage] = useState(1);
	const [pageSize, setPageSize] = useState(itemPerPage); // Default page size

	const handlePageChange = (page) => {
		setCurrentPage(page);
	};
	const { user } = useSelector((store) => store.user);
	const dispatch = useDispatch();

	useEffect(() => {
		if (user === undefined) dispatch(logOut());
	}, [user]);
	const handleSizeChange = (current, size) => {
		setPageSize(size);
		dispatch(handleChange({ name: "itemPerPage", value: size }));
		setCurrentPage(1); // Reset to the first page when the page size changes
	};

	useEffect(() => {
		dispatch(setActivePage(3));
	}, []);
	useEffect(() => {
		dispatch(
			changepageStaff({
				itemPerPage: pageSize,
				page: currentPage,
				search: "",
			})
		);
	}, [search, pageSize, currentPage]);
	const ActivationHandler = (user) => {
		if (user.isActive) {
			dispatch(deActivateUser(user));
		} else {
			dispatch(activateUser(user));
		}
	};

	const { buttonsText } = useSelector((store) => store.lang);
	if (isLoading) return <Loading />;

	let users = [];
	if (staffs !== undefined && staffs.length > 0) {
		users = staffs.map((user, index) => {
			let color = colors[index % 7];
			return (
				<UserCard
					key={index}
					color={color}
					Role={"staff"}
					info={user}
					handler={ActivationHandler.bind(this, user)}
				/>
			);
		});
	}
	return (
		<>
			{users?.length > 0 ? users : <Empty description={empty.empty} />}
			<Center marginTop={"1rem"}>
				<Pagination
					current={currentPage}
					pageSize={pageSize}
					total={pageSize * pages} // Replace with the actual total number of items
					onChange={handlePageChange}
					onShowSizeChange={handleSizeChange}
					showSizeChanger
					pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
				/>
			</Center>
			{/* {user.role === "Admin" && (
				<Button
					width={"fit-content"}
					mt="2rem"
					colorScheme={btnColorScheme[theme]}
					onClick={() => {
						dispatch(exportStaff());
					}}
				>
					{buttonsText.export}
				</Button>
			)} */}
		</>
	);
}

export default AllStaff;
