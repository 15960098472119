import {
	Button,
	Flex,
	Icon,
	Input,
	Textarea,
	Box,
	InputGroup,
	InputRightElement,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { IoIosSend } from "react-icons/io";
import { useSelector } from "react-redux";
import img from "../../assets/images/chat.jpg";

let isTyping = false;
function ChatControle({ sendMessage, type, see }) {
	const { id } = useSelector((store) => store.chat);
	const user = useSelector((store) => store.user.user);
	const [message, setMessage] = useState("");
	useEffect(() => {
		if (message.trim() !== "" && isTyping === false) {
			type(true);
			isTyping = true;
		}
		if (message.trim() === "" && isTyping === true) {
			type(false);
			isTyping = false;
		}
	}, [message]);
	const changeHandler = (e) => {
		setMessage(e.target.value);
	};
	return (
		// <Flex
		// 	gap={"1rem"}
		// 	alignItems={"center"}
		// 	bgImage={img}
		// 	bgRepeat={"repeat"}
		// >
		<InputGroup w={"100%"}>
			<Input
				//bg="white"
				value={message}
				onChange={changeHandler}
				resize={"none"}
				borderRadius=".6rem"
				type={"text"}
				placeholder={"type your message"}
				p={"1rem"}
				onKeyDown={(e) => {
					if (e.key === "Enter") {
						if (message.trim() !== "") {
							sendMessage(message);
						}
						setMessage("");
					}
				}}
			/>
			<InputRightElement w={"3rem"}>
				<Button
					colorScheme={"telegram"}
					borderRadius={"0 .6rem  .6rem 0"}
					onClick={() => {
						if (message.trim() !== "") {
							sendMessage(message);
						}
						setMessage("");
					}}
				>
					<IoIosSend />
				</Button>
			</InputRightElement>
		</InputGroup>
		// </Flex>
	);
}

export default ChatControle;
