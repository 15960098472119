import React, { useEffect } from "react";
import {
  VStack,
  Flex,
  useColorMode,
  Button,
  Card,
  IconButton,
} from "@chakra-ui/react";
import ProductName from "../components/ProductName";
import { useDispatch, useSelector } from "react-redux";
import {
  getProducts,
  changeActivation,
} from "../features/products/ProductSlice";
import Loading from "../components/Loading";
import { Link, useNavigate } from "react-router-dom";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";

import { IoCreateOutline } from "react-icons/io5";
import { FloatButton } from "antd";

function AddOrderOption() {
  const { colorMode } = useColorMode();
  const Navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setActivePage(100));
  }, []);

  const toggleActivation = (item) => {
    dispatch(changeActivation(item));
  };
  useEffect(() => {
    dispatch(getProducts());
  }, [dispatch]);
  const { products, isLoading } = useSelector((store) => store.products);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);

  // const { product } = useSelector((store) => store.lang);
  const { user } = useSelector((store) => store.user);

  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  if (user.role !== "Admin") return <Navigate to={"/404"} />;
  if (isLoading) return <Loading />;
  // console.log(products);
  return (
    <VStack
      position={"relative"}
      // bgColor={"#F5F5F5"}
      // bgColor={colorMode === "dark" ? "#635E6C" : "#DFDBE5"}
      boxShadow={"md"}
      p={"1rem"}
      alignItems={"start"}
      gap={"2rem"}
    >
      <FloatButton
        style={{
          position: "absolute",
        }}
        onClick={() => {
          Navigate("/product");
        }}
        icon={<IoCreateOutline />}
      ></FloatButton>

      {/* <Flex
        direction={"column"}
        justifyContent={"start"}
        gap="1rem"
        width={"100%"}
      >
        <InputGroup>
          <InputLeftAddon p={"1.5rem"}>English</InputLeftAddon>
          <Input
            onChange={handler}
            colorScheme={btnColorScheme[theme]}
            fontSize={"lg"}
            p={"1.5rem"}
            type="text"
            placeholder={product.enterTheProduct}
          ></Input>
        </InputGroup>
        <InputGroup>
          <InputLeftAddon p={"1.5rem"}>Deutsch</InputLeftAddon>
          <Input
            onChange={handler2}
            colorScheme={btnColorScheme[theme]}
            fontSize={"lg"}
            p={"1.5rem"}
            type="text"
            placeholder={product.enterTheProduct}
          ></Input>
        </InputGroup>
        <Button
          width={"fit-content"}
          alignSelf={"end"}
          onClick={submitHandler}
          fontSize={"xl"}
          colorScheme={btnColorScheme[theme]}
          padding={"1.5rem"}
        >
          {product.addProduct}
        </Button> 
       </Flex> */}
      <Flex gap={"1rem"} wrap={"wrap"}>
        {products?.map((item, index) => (
          <ProductName
            key={index}
            item={item}
            toggleActivation={toggleActivation}
          />
        ))}
      </Flex>
    </VStack>
  );
}

export default AddOrderOption;
