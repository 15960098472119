import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Card,
  Button,
  Text,
  useColorMode,
  Avatar,
  Flex,
  Center,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Box,
} from "@chakra-ui/react";
import { FaEllipsisVertical } from "react-icons/fa6";
import { Pagination } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
  getChats,
  getChatsFiltered,
  handleChange,
} from "../features/chat/chatSlice";
import { Link } from "react-router-dom";
import { Empty, Select } from "antd";
// import Loading from "../components/Loading";
import { logOut } from "../features/user/UserSlice";
import { markAsUnread } from "../features/chat/chatSlice";
import FilterChats from "../components/chat/FilterChats";
import Loading from "../components/Loading";
let allChats = 0;
let options = [];
function AllChats({ isOpen, onClose }) {
  const { user } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const { chat } = useSelector((store) => store.lang);
  // const { states, controlers } = useSelector((store) => store.lang);

  // options = states.map((item, index) => {
  // 	return {
  // 		label: item,
  // 		index,
  // 	};
  // });
  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  let { isLoading, chats, pages, itemPerPage, states } = useSelector(
    (store) => store.chat
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(itemPerPage); // Default page size

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleSizeChange = (current, size) => {
    setPageSize(size);
    dispatch(handleChange({ name: "itemPerPage", value: size }));
    setCurrentPage(1); // Reset to the first page when the page size changes
  };
  // const navigate = useNavigate();
  if (chats === undefined) chats = [];
  allChats = chats?.length;
  const colors = [
    "rgba(255, 99, 132, 8)",
    "rgba(255, 159, 64, 8)",
    "rgba(255, 205, 86, 8)",
    "rgba(75, 192, 192, 8)",
    "rgba(54, 162, 235, 8)",
    "rgba(153, 102, 255, 8)",
    "rgba(201, 203, 207, 8)",
  ];

  const { colorMode } = useColorMode();
  useEffect(() => {
    if (isOpen === true) {
      // console.log(isOpen);
      dispatch(getChatsFiltered({ page: currentPage, itemPerPage: pageSize }));
    }
  }, [isOpen, currentPage, pageSize, states]);
  // const [unRead, setUnread] = useState(false);
  const { heading, empty } = useSelector((store) => store.lang);

  let items = chats.map((item, index) => {
    let color = colors[index % 7];
    // console.log(item);
    return (
      <Flex width={"100%"} justifyContent={"space-between"}>
        <Link
          key={index}
          onClick={onClose}
          to={`/chat`}
          state={{ chatId: item.chat.id, clinicId: item.clinicId }}
        >
          <Flex
            bgColor={
              // "gray.100"

              user.role === "Clinic"
                ? item.chat.clinicMarkAsRead
                  ? "gray.100"
                  : ""
                : item.chat.staffMarkAsRead
                ? "gray.100"
                : ""
            }
            mb={"1px"}
            w={"240px"}
            transition={"all .2s"}
            alignItems={"center"}
            justifyContent={"start"}
            _hover={{
              backgroundColor: colorMode === "dark" ? "#232D3F" : "#ECF2FF",
              cursor: "pointer",
            }}
            borderRadius={"0.5rem"}
          >
            {/* {console.log(item, user.role)} */}
            <Avatar bgColor={color} color={"white"} name={item.chat.title} />
            <Card
              transition={"all .2s"}
              boxShadow={"none"}
              pos={"relative"}
              bg={"transparent"}
              rounded={"md"}
              h={"4rem"}
              mt={"1px"}
              w="100%"
              p={".5rem"}

              // boxShadow={"sm"}
            >
              <Button
                textAlign={"left"}
                // w={"fit-content"}
                justifySelf={"start"}
                alignSelf={"start"}
                justifyContent={"start"}
                variant={"link"}
                colorScheme={"blue"}
                fontSize={".9rem"}
                textOverflow={"ellipsis"}
                isTruncated
                maxW={"160px"}
              >
                {item.chat.title}
              </Button>
              {/* </Link> */}
              <Text
                fontSize={".8rem"}
                textOverflow={"ellipsis"}
                isTruncated
                py={".2rem"}
                textIndent={"1rem"}
                w={"80%"}
              >
                {item.latestMessage === null ? "" : item.latestMessage.content}
              </Text>
              {item.unreadCount > 0 && (
                <Text
                  textAlign={"center"}
                  color={"white"}
                  fontSize={"12px"}
                  position={"absolute"}
                  bottom={"2"}
                  right={"2"}
                  // justifySelf={"end"}
                  p={"2px"}
                  w={"fit-content"}
                  // h="14px"
                  rounded={"full"}
                  minW={"1rem"}
                  bgColor={"blue.300"}
                  // bg={"green"}
                >
                  {item.unreadCount}
                </Text>
              )}
            </Card>
          </Flex>
        </Link>
        <Menu>
          <MenuButton
            as={IconButton}
            aria-label="Options"
            icon={<FaEllipsisVertical />}
            variant="unstile"
          />
          <MenuList>
            <MenuItem
              onClick={() => {
                dispatch(markAsUnread(item.chat.id));
                dispatch(
                  getChatsFiltered({
                    page: currentPage,
                    itemPerPage: pageSize,
                  })
                );
                // item.chat.staffMarkAsRead =
                // 	user.role !== "Clinic";
                // item.chat.clinicMarkAsRead =
                // 	user.role === "Clinic";
              }}
            >
              {chat.mark}
            </MenuItem>
          </MenuList>
        </Menu>
        {/* <Button colorScheme="transparent" color={"black"}>
					<FaEllipsisVertical />
				</Button> */}
      </Flex>
    );
  });

  return (
    <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
      {/* <FilterChats options={options} /> */}
      <DrawerOverlay />
      <DrawerContent pos={"relative"}>
        <DrawerCloseButton />
        <DrawerHeader>{heading.chats}</DrawerHeader>

        <DrawerBody>
          <FilterChats states2={states} />
          {isLoading ? (
            <Loading></Loading>
          ) : items?.length > 0 ? (
            <>{items}</>
          ) : (
            <Empty description={empty.empty} />
          )}
        </DrawerBody>

        <DrawerFooter>
          <Center mt={"1rem"}>
            <Pagination
              size="small"
              current={currentPage}
              pageSize={pageSize}
              total={pageSize * pages} // Replace with the actual total number of items
              onChange={handlePageChange}
              onShowSizeChange={handleSizeChange}
              showSizeChanger
              pageSizeOptions={["10", "20", "30", "40", "50"]} // Customize the available page sizes
            />
          </Center>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
}
export default AllChats;
