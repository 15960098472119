export const validate = (values) => {
	// Helper function to perform validation and return an error object if validation fails
	const validateField = (value, regex, errorMessage) => {
		return value.match(regex) === null
			? { respond: "error", msg: errorMessage }
			: null;
	};
	if (values.role === "clinic") {
		const {
			username,
			name,
			email,
			password,
			phone,
			doctorname,
			country,
			address,
		} = values;

		// Validation using regular expressions
		const isAlphabetic = /^[a-zA-Z-'s]+$/;
		const isPhoneNumber = /^\d{7,16}$/;
		const isAlphaNumeric = /^[a-zA-Z0-9\s]+$/;
		const isAlphabeticWithSpaces = /^[a-zA-Z\s-'s]+$/;

		// Validation checks
		const usernameError = validateField(
			username,
			isAlphabetic,
			"Invalid username"
		);
		if (usernameError) return usernameError;

		const nameError = validateField(
			name,
			isAlphabeticWithSpaces,
			"Invalid name"
		);
		if (nameError) return nameError;

		const emailError =
			email === "" ? { respond: "error", msg: "Invalid email" } : null;
		if (emailError) return emailError;

		const phoneError = validateField(
			phone,
			isPhoneNumber,
			"Invalid phone number"
		);
		if (phoneError) return phoneError;

		const passwordError = validateField(
			password,
			/^.{8,40}$/,
			"Invalid password"
		);
		if (passwordError) return passwordError;

		const doctornameError = validateField(
			doctorname,
			isAlphabetic,
			"Invalid doctor name"
		);
		if (doctornameError) return doctornameError;

		const countryError = validateField(
			country,
			isAlphabetic,
			"Invalid country"
		);
		if (countryError) return countryError;

		const addressError = validateField(
			address,
			isAlphaNumeric,
			"Invalid address"
		);
		if (addressError) return addressError;
	} else {
		const { userName, name, password } = values;

		// Validation checks
		const usernameError = validateField(
			userName,
			/^[a-zA-Z-'s]+$/,
			"Invalid username"
		);
		if (usernameError) return usernameError;

		const nameError = validateField(
			name,
			/^[a-zA-Z\s-'s]+$/, // Allow spaces in the name
			"Invalid name"
		);
		if (nameError) return nameError;

		const passwordError = validateField(
			password,
			/^.{8,40}$/,
			"Invalid password"
		);
		if (passwordError) return passwordError;
	}

	return { respond: "success", msg: "submitted" };
};
