import { useState, useEffect } from "react";
import {
  StepIndicator,
  Step,
  StepDescription,
  StepIcon,
  StepNumber,
  Stepper,
  StepStatus,
  StepTitle,
  StepSeparator,
  useSteps,
  Box,
  ButtonGroup,
  Button,
  Heading,
  Flex,
  FormControl,
  GridItem,
  FormLabel,
  Input,
  Select,
  SimpleGrid,
  InputGroup,
  Textarea,
  VStack,
  Text,
} from "@chakra-ui/react";
import UploadProgress from "../components/order/UploadProgress";
import { UploadOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import {
  buildTheUis,
  clearTheData,
  getProducts,
} from "../features/products/ProductSlice";
import { useSelector, useDispatch } from "react-redux";

import CustomBox from "../components/CustomBox";
import { postOrder, postOrderForClinic } from "../features/order/orderSlice";
import { Select as SelectProduct } from "antd";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import CustomLoading from "../components/CustomLoading";
import { setActivePage } from "../features/chat/chatSlice";
import { logOut } from "../features/user/UserSlice";
import { message } from "antd";
import { IoContractOutline } from "react-icons/io5";
import QuestionsWithoutInput from "../components/product/QuestionsWithoutInput";
import { validateTheProducts } from "../utils/Tools";

const patientInfo = {
  firstname: "",
  lastname: "",
  dateOfBirth: "",
  medicalRecordNumber: "",
};
const orderInfo = {
  isUrgent: false,
  note: "",
  images: [],
};

const Form1 = ({ handler, values }) => {
  const { heading, orderLabels } = useSelector((store) => store.lang);
  return (
    <>
      <Heading
        mt={"2rem"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        mb="2%"
      >
        {heading.patientInformation}
      </Heading>
      <FormControl mr="5%" isRequired mb={"1rem"}>
        <FormLabel htmlFor="fname" fontWeight={"normal"}>
          {orderLabels.firstName}
        </FormLabel>
        <Input
          onChange={handler}
          name="firstname"
          id="fname"
          placeholder={orderLabels.firstName}
          value={values.firstname}
        />
      </FormControl>
      <FormControl mr="5%" isRequired>
        <FormLabel htmlFor="sname" fontWeight={"normal"}>
          {orderLabels.lastName}
        </FormLabel>
        <Input
          onChange={handler}
          name="lastname"
          id="sname"
          placeholder={orderLabels.lastName}
          value={values.lastname}
        />
      </FormControl>
      <FormControl mt="2%" isRequired>
        <FormLabel htmlFor="id">{orderLabels.id}</FormLabel>
        <Input
          onChange={handler}
          name="medicalRecordNumber"
          id="id"
          type={"number"}
          value={values.medicalRecordNumber}
        ></Input>
      </FormControl>
      <FormControl mt="2%" isRequired>
        <FormLabel htmlFor="birthDate" fontWeight={"normal"}>
          {orderLabels.birthDate}
        </FormLabel>
        <Input
          type="date"
          onChange={handler}
          name="dateOfBirth"
          id="birthDate"
          value={values.dateOfBirth}
        />
      </FormControl>
    </>
  );
};

const Form2 = ({ products, values2, values3, handler2, choice }) => {
  const { heading, orderLabels, orderType, language } = useSelector(
    (store) => store.lang
  );
  const { uis, orderAnswers, orderQuestions, ids } = useSelector(
    (state) => state.products
  );
  let items = [];
  items = products?.filter((item) => {
    return item.isActive === true;
  });

  // console.log(orderAnswers);
  // console.log(orderQuestions);
  // console.log(ids);
  // console.log(uis);
  let options = items?.map((item) => {
    return {
      label: language === "English" ? item.name.en : item.name.de,
      value: item.id,
    };
  });

  return (
    <VStack gap={4}>
      <Heading
        mt={"1rem"}
        w="100%"
        textAlign={"center"}
        fontWeight="normal"
        mb="2%"
      >
        {heading.orderDetails}
      </Heading>
      <FormControl as={GridItem} colSpan={6} isRequired>
        <FormLabel
          htmlFor="order"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
          mt="2%"
        >
          {orderLabels.products}
        </FormLabel>

        <Flex justifyContent={"start"} gap={"2rem"} wrap={"wrap"}>
          {/* {items} */}

          <SelectProduct
            size="large"
            mode="multiple"
            style={{
              width: "100%",
            }}
            defaultValue={values3}
            // placeholder="Tags Mode"
            onChange={choice}
            options={options}
          />
        </Flex>
      </FormControl>
      {uis.map((ui) => {
        return (
          <Flex
            alignSelf={"start"}
            rounded={"md"}
            width={"100%"}
            flexDir={"column"}
            outline={"1px solid gray"}
            alignItems={"start"}
          >
            <Box p={"1rem"}>
              <Text>{ui.name.en}</Text>
            </Box>
            {ui?.data?.map((item, index) => {
              return (
                <QuestionsWithoutInput
                  item={item}
                  key={index}
                  num={`Q${index + 1}`}
                />
              );
            })}
          </Flex>
        );
      })}

      <FormControl as={GridItem} colSpan={[6, 3]} isRequired>
        <FormLabel
          htmlFor="priority"
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          {orderLabels.typeOfOrder}
        </FormLabel>
        <Select
          id="priority"
          name="isUrgent"
          autoComplete="priority"
          placeholder="Select option"
          focusBorderColor="brand.400"
          shadow="sm"
          size="sm"
          w="full"
          rounded="md"
          onChange={handler2}
          value={values2.isUrgent}
        >
          <option value={true}>{orderType.urgent}</option>
          <option value={false}>{orderType.normal}</option>
        </Select>
      </FormControl>
      <FormControl id="details" mt={1}>
        <FormLabel
          fontSize="sm"
          fontWeight="md"
          color="gray.700"
          _dark={{
            color: "gray.50",
          }}
        >
          {orderLabels.note}
        </FormLabel>
        <Textarea
          onChange={handler2}
          rows={3}
          resize={"none"}
          shadow="sm"
          name="note"
          focusBorderColor="brand.400"
          fontSize={{
            sm: "sm",
          }}
          value={values2.note}
        />
      </FormControl>
    </VStack>
  );
};

const Form3 = ({ props }) => {
  const { heading, orderLabels, buttonsText } = useSelector(
    (store) => store.lang
  );
  return (
    <>
      <Heading mt={"1rem"} w="100%" textAlign={"center"} fontWeight="normal">
        {heading.files}
      </Heading>
      <SimpleGrid columns={1} spacing={6}>
        <FormControl as={GridItem} colSpan={[3, 2]} isRequired>
          <FormLabel
            fontSize="sm"
            fontWeight="md"
            color="gray.700"
            _dark={{
              color: "gray.50",
            }}
          >
            {orderLabels.files}
          </FormLabel>
          <InputGroup size="sm">
            <Upload multiple {...props} accept=".stl,.png,.jpg,.jpeg,.zip,.rar">
              <Button icon={<UploadOutlined />}>
                {buttonsText.selectFile}
              </Button>
            </Upload>

            {/* <UploadFiels /> */}
            {/* <Input
							type="file"
							multiple
							onChange={handler}
							accept=".jpg, .jpeg, .png, .stl"
						/> */}
          </InputGroup>
        </FormControl>
      </SimpleGrid>
    </>
  );
};
// let values3 = [];
export default function Multistep() {
  const location = useLocation();
  let is_ok = location.state?.is_ok;
  let clinicInfo = location.state?.clinicInfo;

  const { buttonsText, heading, stepText, error } = useSelector(
    (store) => store.lang
  );
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const { isLoading, createdSuccessfuly } = useSelector((store) => store.order);

  const { user } = useSelector((store) => store.user);
  const [values3, setValues3] = useState([]);
  useEffect(() => {
    if (user === undefined) dispatch(logOut());
  }, [user]);
  const [values, setValues] = useState(patientInfo);
  const [values2, setValues2] = useState(orderInfo);

  // const choiceProducts = (item) => {
  // 	if (values3.includes(item.id)) {
  // 		values3 = values3.filter((id) => id !== item.id);
  // 	} else values3.push(item.id);
  // 	// console.log(values3);
  // };

  const choiceProducts = (item) => {
    // if (values3.includes(item.id)) {
    // 	values3 = values3.filter((id) => id !== item.id);
    // } else values3.push(item.id);
    dispatch(buildTheUis({ data: item }));
    setValues3(item);
    // console.log(values3);
  };
  const [fileList, setFileList] = useState([]);

  const handler = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handler2 = (e) => {
    setValues2({ ...values2, [e.target.name]: e.target.value });
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      setFileList((prevFileList) => [...prevFileList, file]);
      return false;
    },
    fileList,
  };
  const { products, ids, orderAnswers, orderQuestions } = useSelector(
    (store) => store.products
  );
  const onSubmit = (e) => {
    e.preventDefault();
    let arr = products.filter((e) => {
      return ids.includes(e.id);
    });
    // console.log(ids);
    // console.log(arr);

    // try {

    const order = {
      patientInfo: values,
      isUrgent: values2.isUrgent,
      ids: [...values3],
      note: values2.note,
      AnswerIds: [...orderAnswers],
      // images: fileList,
    };
    // console.log(order.ids);
    if (order.ids.length === 0) {
      message.error(error.productRequired);
      return;
    }
    if (validateTheProducts(arr, orderAnswers, orderQuestions) === false) {
      message.error(error.validateTheAnswers);
      return;
    }
    if (order.patientInfo.firstname === "") {
      message.error(error.firstName);
      return;
    }
    if (order.patientInfo.lastname === "") {
      message.error(error.lastName);
      return;
    }
    if (order.patientInfo.medicalRecordNumber === "") {
      message.error(error.id);
      return;
    }
    if (order.patientInfo.dateOfBirth === "") {
      message.error(error.date);
      return;
    }

    // console.log(order.patientInfo, order.isUrgent);

    // Append patient information
    const formData = new FormData();
    formData.append("PatientInfo.FirstName", order.patientInfo.firstname);
    formData.append("PatientInfo.LastName", order.patientInfo.lastname);
    formData.append("PatientInfo.DateOfBirth", order.patientInfo.dateOfBirth);
    formData.append(
      "PatientInfo.MedicalRecordNumber",
      order.patientInfo.medicalRecordNumber
    );
    formData.append("note", order.note);
    formData.append("isUrgent", order.isUrgent);

    // Append Ids
    for (let i = 0; i < order.ids.length; i++) {
      // console.log(order.ids[i]);
      formData.append("Ids", order.ids[i]);
    }
    for (let i = 0; i < order.AnswerIds.length; i++) {
      // console.log(order.ids[i]);
      formData.append("AnswerIds", order.AnswerIds[i]);
    }

    for (let i = 0; i < fileList.length; i++) {
      formData.append("Images", fileList[i]);
    }
    if (is_ok) {
      let data = {
        first: formData,
        second: clinicInfo,
      };

      dispatch(postOrderForClinic(data));
    } else dispatch(postOrder(formData));
    dispatch(clearTheData());
    setValues3([]);
  };
  const steps = [
    { title: stepText.first, description: heading.patientInformation },
    { title: stepText.second, description: heading.orderDetails },
    { title: stepText.third, description: heading.files },
  ];
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });
  const [step, setStep] = useState(0);
  const dispatch = useDispatch();
  const { isUploading } = useSelector((store) => store.order);
  useEffect(() => {
    dispatch(setActivePage(100));
  }, []);

  useEffect(() => {
    dispatch(getProducts());
    dispatch(clearTheData());
  }, []);
  if (user.role !== "Clinic" && is_ok !== true) return <Navigate to={"/403"} />;
  if (createdSuccessfuly) return <Navigate to={"/success"} />;
  if (isUploading) return <UploadProgress></UploadProgress>;
  if (isLoading) return <CustomLoading state={isLoading} />;
  // console.log(uis);
  return (
    <>
      {/* <CustomLoading state={isLoading} /> */}
      <Box maxWidth={800} p={6} m="10px auto" as="form">
        <Stepper
          colorScheme={btnColorScheme[theme]}
          size="lg"
          index={activeStep}
        >
          {steps.map((step, index) => (
            <Step
              key={index}
              onClick={() => {
                setActiveStep(index);
                setStep(index);
              }}
            >
              <StepIndicator style={{ cursor: "pointer" }}>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>

              <Box flexShrink="0">
                <StepTitle>{step.title}</StepTitle>
                <StepDescription>{step.description}</StepDescription>
              </Box>

              <StepSeparator />
            </Step>
          ))}
        </Stepper>
        {step === 0 ? (
          <Form1 values={values} handler={handler} />
        ) : step === 1 ? (
          <Form2
            products={products}
            values2={values2}
            handler2={handler2}
            values3={values3}
            choice={choiceProducts}
          />
        ) : (
          <Form3 props={props} />
        )}
        <ButtonGroup mt="5%" w="100%">
          <Flex w="100%" justifyContent="space-between">
            <Flex>
              <Button
                onClick={() => {
                  setStep(step - 1);
                  setActiveStep(step - 1);
                  // setProgress(progress - 33.33);
                }}
                isDisabled={step === 0}
                colorScheme={btnColorScheme[theme]}
                variant="solid"
                w="7rem"
                mr="5%"
              >
                {buttonsText.back}
              </Button>
              <Button
                w="7rem"
                isDisabled={step === 2}
                onClick={() => {
                  setStep(step + 1);
                  setActiveStep(step + 1);
                }}
                colorScheme={btnColorScheme[theme]}
                variant="outline"
              >
                {buttonsText.next}
              </Button>
            </Flex>
            {step === 2 ? (
              <Button
                type="submit"
                w="7rem"
                colorScheme="red"
                variant="solid"
                onClick={onSubmit}
              >
                {buttonsText.submit}
              </Button>
            ) : null}
          </Flex>
        </ButtonGroup>
      </Box>
    </>
  );
}
