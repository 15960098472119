import React from "react";
import { Spinner, Flex } from "@chakra-ui/react";
import { useSelector } from "react-redux";
const Loading = () => {
	const { mainColor, theme } = useSelector((store) => store.theme);
	return (
		<Flex justifyContent={"center"} alignItems={"center"} height={"50vh"}>
			<Spinner
				width={"4rem"}
				thickness="4px"
				speed="0.8s"
				emptyColor="gray.200"
				color={mainColor[theme]}
				size="lg"
				height={"4rem"}
			/>
		</Flex>
	);
};
export default Loading;
