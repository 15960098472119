import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Base64 from "base-64";
import axios from "axios";
import { getTheError } from "../../utils/ErorrHandling";
import {
  removeUserFromLocalStorage,
  addUserToLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/LocalStorage";

import { toast } from "react-toastify";
import { BASEURL, CLINIC, LOGIN, STAFF, USER } from "../../utils/urls";
import { message } from "antd";
const initialState = {
  isLoading: false,
  user: getUserFromLocalStorage(),
  image: "",
  picLoading: false,
};
//add back slash
export const changeClinicPassword = createAsyncThunk(
  "user/changeClinicPassword",
  async (user, thunkAPI) => {
    try {
      let pass = { password: user.password };
      const resp = await axios.put(
        USER + `changepassword/${user.userId}`,
        pass,
        {
          headers: {
            authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      // console.log(error.status);
      return getTheError(error, thunkAPI);
    }
  }
);
export const uploadMyPic = createAsyncThunk(
  "user/uploadMyPic",
  async (data, thunkAPI) => {
    try {
      const resp = await axios.post(BASEURL + "/api/user/profilemypic", data, {
        headers: {
          "Content-Type": "multipart/form-data",
          authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      // console.log(error.response);
      return getTheError(error, thunkAPI);
    }
  }
);

export const changeUserPassword = createAsyncThunk(
  "user/changeUserPassword",
  async (user, thunkAPI) => {
    try {
      let pass = { password: user.password };
      const resp = await axios.put(
        USER + `changepassword/${user.userId}`,
        pass,
        {
          headers: {
            authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const deActivateUser = createAsyncThunk(
  "user/deActivateUser",
  async (user, thunkAPI) => {
    try {
      const resp = await axios.put(
        USER + `deactivate/${user.userId}`,
        {},
        {
          headers: {
            authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return user.userId;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const activateUser = createAsyncThunk(
  "user/activateUser",
  async (user, thunkAPI) => {
    try {
      const resp = await axios.put(
        USER + `activate/${user.userId}`,
        {},
        {
          headers: {
            authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return user.userId;
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateClinic = createAsyncThunk(
  "user/updateClinic",
  async (user, thunkAPI) => {
    try {
      user.password = "12345690";
      const resp = await axios.put(CLINIC + `updateclinic/${user.id}`, user, {
        headers: {
          authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const updateUser = createAsyncThunk(
  "user/updateUser",
  async (user, thunkAPI) => {
    try {
      user.password = "12345690";
      user.role = 0;
      const resp = await axios.put(STAFF + `updatestaff/${user.id}`, user, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

export const loginUser = createAsyncThunk(
  "user/loginUser",
  async (user, thunkAPI) => {
    try {
      const encode = Base64.encode(`${user.username}:${user.password}`);
      let config = {
        method: "post",
        url: LOGIN,
        headers: { Authorization: "Basic " + encode },
      };
      let response = await axios(config);
      response.data.data.token = response.headers.getAuthorization();
      return response.data;
    } catch (error) {
      if (
        !error.response ||
        !error.response.data ||
        !error.response.data.message ||
        error.response.data.code === "A7DF"
      ) {
        return thunkAPI.rejectWithValue(
          thunkAPI.getState().lang.error.serverError
        );
      }

      return thunkAPI.rejectWithValue(error.response.data.message);
      // return thunkAPI.rejectWithValue(getTheError(error));
    }
  }
);

export const createUser = createAsyncThunk(
  "user/createUser",
  async (data, thunkAPI) => {
    try {
      const { url, user, token } = data;

      const resp = await axios.post(url, user, {
        headers: {
          Authorization: token,
        },
      });

      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    logOut(state) {
      state.user = null;
      removeUserFromLocalStorage();
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loginUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(loginUser.fulfilled, (state, { payload }) => {
      const user = payload?.data;
      state.isLoading = false;
      state.user = user;
      toast.success("welcome back");
      addUserToLocalStorage(user);
    });
    builder.addCase(loginUser.rejected, (state, { payload }) => {
      state.isLoading = false;

      toast.error(payload);
    });
    builder.addCase(createUser.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createUser.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(createUser.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });

    builder.addCase(updateClinic.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateClinic.fulfilled, (state, { payload }) => {
      state.isLoading = false;
    });
    builder.addCase(updateClinic.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(updateUser.pending, (state) => {});
    builder.addCase(updateUser.fulfilled, (state) => {
      message.success("success");
    });
    builder.addCase(updateUser.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(changeClinicPassword.pending, (state) => {});
    builder.addCase(changeClinicPassword.fulfilled, (state) => {
      message.success("sucess");
    });
    builder.addCase(changeClinicPassword.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(changeUserPassword.pending, (state) => {});
    builder.addCase(changeUserPassword.fulfilled, (state) => {});
    builder.addCase(changeUserPassword.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(uploadMyPic.pending, (state) => {
      state.picLoading = true;
    });
    builder.addCase(uploadMyPic.fulfilled, (state, { payload }) => {
      // console.log(payload);
      state.picLoading = false;
      state.user.profileImage = payload?.data.filename;
      // console.log(state.user);
      addUserToLocalStorage(state.user);
    });
    builder.addCase(uploadMyPic.rejected, (state, { payload }) => {
      state.picLoading = false;
      message.error(payload);
    });

    builder.addCase(deActivateUser.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(deActivateUser.fulfilled, (state, { payload }) => {});
    builder.addCase(deActivateUser.rejected, (state, { payload }) => {
      message.error(payload);
    });
    builder.addCase(activateUser.pending, (state) => {
      // state.isLoading = true;
    });
    builder.addCase(activateUser.fulfilled, (state, { payload }) => {
      // state.user.isActive = true;
    });
    builder.addCase(activateUser.rejected, (state, { payload }) => {
      message.error(payload);
    });
  },
});
export const { logOut } = userSlice.actions;
export default userSlice.reducer;
