import React from "react";
import {
	Table,
	Thead,
	Tbody,
	Tfoot,
	Tr,
	Th,
	Td,
	TableCaption,
	TableContainer,
} from "@chakra-ui/react";
import TableRow from "../components/TableRow";
import { useSelector } from "react-redux";
function HistoryOfEdit() {
	const { Histories } = useSelector((store) => store.order.order);
	const rows =
		Histories === undefined
			? []
			: Histories.map((item) => <TableRow data={item} />);
	return (
		<TableContainer width={"100%"} boxShadow={"md"} p={"1rem"}>
			<Table variant={"striped"} colorScheme="purple">
				<TableCaption>the history of order</TableCaption>
				<Thead>
					<Tr>
						<Th>User</Th>
						<Th>Date</Th>
						<Th>What happend</Th>
					</Tr>
				</Thead>
				<Tbody>{rows}</Tbody>
			</Table>
		</TableContainer>
	);
}

export default HistoryOfEdit;
