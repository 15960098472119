import {
  VStack,
  Button,
  Divider,
  Box,
  AbsoluteCenter,
  Text,
  Flex,
  Image,
  useColorMode,
} from "@chakra-ui/react";
// import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useState } from "react";
import { AddIcon } from "@chakra-ui/icons";
import CreateUser from "../pages/CreateUser";
import { useSelector } from "react-redux";
import img from "../assets/images/LOGO.png";
// import img from "../assets/images//LOGORAMADAN.png";
// import wimg from "../assets/images/photo.png";
import { PiChartLineUpLight, PiUsers, PiArticle } from "react-icons/pi";
import img1 from "../assets/images/thems/ramadan/1.gif";
import img2 from "../assets/images/thems/ramadan/2.gif";
function Sidebar({ onOpen, isOpen, onClose }) {
  // const [t, setT] = useTranslation();
  // const [num, setNum] = useState(2);
  const { user } = useSelector((store) => store.user);
  const { theme, btnColorScheme } = useSelector((store) => store.theme);
  const activePage = useSelector((store) => store.chat.activePage);
  // console.log(activePage);
  const role = user.role;
  const { colorMode } = useColorMode();

  const { sideBar } = useSelector((store) => store.lang);

  return (
    <VStack w={"100%"} mt={"2rem"}>
      <Flex width={"100%"} justifyContent={"center"} mb={"1rem"}>
        <Image
          rounded={"md"}
          src={img}
          // src={colorMode === "dark" ? img : img}
          width={"90%"}
          maxW={"240px"}
        />
        {/* <Avatar src={img1}></Avatar> <Avatar src={img2}></Avatar>
					<Avatar src={img3}></Avatar> */}
      </Flex>
      {role === "Admin" && (
        <Button
          width={"100%"}
          leftIcon={<AddIcon />}
          colorScheme={btnColorScheme[theme]}
          onClick={onOpen}
        >
          {sideBar.addUser}
        </Button>
      )}
      {role === "Admin" && (
        <Link
          style={{
            display: "block",
            width: "100%",
          }}
          to="add-option"
        >
          <Button
            width={"100%"}
            leftIcon={<AddIcon />}
            colorScheme={btnColorScheme[theme]}
          >
            {sideBar.addProduct}
          </Button>
        </Link>
      )}
      {role === "Admin" && (
        <Link
          style={{
            display: "block",
            width: "100%",
          }}
          to="addAd"
        >
          <Button
            width={"100%"}
            leftIcon={<AddIcon />}
            colorScheme={btnColorScheme[theme]}
          >
            {sideBar.addAd}
          </Button>
        </Link>
      )}

      {role === "Clinic" && (
        <Link
          style={{
            display: "block",
            width: "100%",
          }}
          to="add-order"
        >
          <Button
            width={"100%"}
            leftIcon={<AddIcon />}
            colorScheme={btnColorScheme[theme]}
          >
            {sideBar.addOrder}
          </Button>
        </Link>
      )}

      <Box position={"relative"} my={"2rem"}>
        <Divider></Divider>
        <AbsoluteCenter>{sideBar.navigation}</AbsoluteCenter>
      </Box>
      <Link
        style={{
          display: "block",
          width: "100%",
        }}
        to="/"
      >
        <Button
          isActive={activePage === 1}
          width={"100%"}
          colorScheme={btnColorScheme[theme]}
          variant="ghost"
          // onClick={() => setNum(2)}
          justifyContent="left"
          gap={"1rem"}
        >
          {<PiChartLineUpLight fontSize={"1.6rem"} />}
          <Text>{sideBar.statistics}</Text>
        </Button>
      </Link>
      <Link
        style={{
          display: "block",
          width: "100%",
        }}
        to="all-orders"
      >
        <Button
          isActive={activePage == 2}
          width={"100%"}
          colorScheme={btnColorScheme[theme]}
          variant="ghost"
          // onClick={() => setNum(1)}
          justifyContent="left"
          gap={"1rem"}
        >
          {<PiArticle fontSize={"1.6rem"} />}
          <Text>{sideBar.allOrders}</Text>
        </Button>
      </Link>
      {role === "Clinic" || (
        <Link
          to="/all-users"
          state={{ Role: "clinic" }}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Button
            isActive={activePage === 4}
            width={"100%"}
            // leftIcon={<AddIcon />}
            colorScheme={btnColorScheme[theme]}
            variant="ghost"
            // onClick={() => setNum(3)}
            justifyContent="left"
            gap={"1rem"}
          >
            {<PiUsers fontSize={"1.6rem"} />}
            <Text>{sideBar.clinics}</Text>
          </Button>
        </Link>
      )}
      {role === "Clinic" || (
        <Link
          to="/all-users"
          state={{ Role: "staff" }}
          style={{
            display: "block",
            width: "100%",
          }}
        >
          <Button
            isActive={activePage === 3}
            width={"100%"}
            // leftIcon={<AddIcon />}
            colorScheme={btnColorScheme[theme]}
            variant="ghost"
            // onClick={() => setNum(4)}
            justifyContent="left"
            gap={"1rem"}
          >
            {<PiUsers fontSize={"1.6rem"} />}
            <Text>{sideBar.staffs}</Text>
          </Button>
        </Link>
      )}
      <CreateUser isOpen={isOpen} onClose={onClose}></CreateUser>

      {theme === 1 && (
        <Box mt={"2rem"} p={"1rem"}>
          <Image src={colorMode === "light" ? img1 : img2}></Image>
        </Box>
      )}
    </VStack>
  );
}

export default Sidebar;
