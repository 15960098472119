import { Flex, Button, TabPanel, Text, Badge } from "@chakra-ui/react";
import { BASEURL } from "../../utils/urls";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { downloadFile, exportFile } from "../../features/order/orderSlice";

import ImageViewer from "../imageViewer";

import { Progress } from "antd";

function Files({ order }) {
	const dispatch = useDispatch();

	const { buttonsText } = useSelector((store) => store.lang);
	const { dArray } = useSelector((store) => store.order);

	return (
		<>
			<TabPanel pos={"relative"}>
				{/* <MyGallery /> */}

				<Flex
					mt="1rem"
					justifyContent={"space-evenly"}
					alignItems={"center"}
				>
					<ImageViewer
						isEmpty = {order?.imageFiles?.length===0}
						images={order?.imageFiles?.map((item) => {
							if (typeof item?.fileName === "string") {
								if (
									item.fileName[
										item.fileName?.length - 1
									].toLowerCase() === "g"
								) {
									return {
										src: `${BASEURL}/api/veHFSaSyDihimAicqjtcpYuPwpxvdX/${item.fileName}`,
										alt: "",
									};
								}
							}
						})}
					/>
				</Flex>
				<Flex
					p={".5rem"}
					direction={"column"}
					border={"1px solid gray"}
					mt={"2rem"}
					borderRadius={"1rem"}
				>
					{order?.imageFiles?.map((item, index) => {
						return (
							<Flex
								borderBottom={
									index !== order.imageFiles.length - 1 &&
									"1px solid gray"
								}
								direction={"column"}
								w={"100%"}
							>
								<Flex
									key={index}
									// colSpan={10}
									rounded={"sm"}
									p={".5rem"}
									boxShadow={"sm"}
									gap="1rem"
									alignItems={"center"}
								>
									<Text
										p=".2rem"
										w={"70%"}
										colorScheme={"blue"}
										// color="yellow.500"
										fontWeight={"semibold"}
										letterSpacing={"1.1px"}
										overflow={"hidden"}
										whiteSpace={"nowrap"}
									>
										{item.displayName}
									</Text>
									<Badge
										textAlign={"center"}
										colorScheme={"blue"}
										w={"10%"}
										h={"fit-content"}
										rounded={"full"}
									>
										{item.displayName.slice(
											item.displayName.length - 3,
											item.displayName.length
										)}
									</Badge>

									<Button
										colorScheme={"blue"}
										variant={"outline"}
										w={"20%"}
										onClick={() => {
											// console.log(order);
											dispatch(
												downloadFile({
													fName: order.patient
														.firstName,
													lName: order.patient
														.lastName,
													fileName: item.fileName,
													displayName:
														item.displayName,
													index: index,
												})
											);
										}}
									>
										{buttonsText.download}
									</Button>
								</Flex>
								{dArray[index].isLoading && (
									<Flex w={"90%"} justifyContent={"center"}>
										<Progress
											strokeColor={"#0088cc"}
											size={"large"}
											percent={90}
											type="line"
											format={() =>
												`${dArray[index].loaded} MB`
											}
										/>
									</Flex>
								)}
								{/* // <Text>{dArray[index].loaded}</Text> */}
							</Flex>
						);
					})}
				</Flex>
			</TabPanel>
		</>
	);
}

export default Files;
