import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { message } from "antd";
import axios from "axios";
import { getTheError } from "../../utils/ErorrHandling";
import { removeUserFromLocalStorage } from "../../utils/LocalStorage";
import { BASEURL } from "../../utils/urls";

const initialFilterState = {
  search: "",
  orderType: "all",
  state: "all",
  startDate: "all",
  endDate: "all",
  productId: "all",
  sortedAsc: false,
  priority: "all",
  // clinicName: "all",
};
const initialState = {
  isLoading: false,
  pdfIsloading: false,
  updateIsLoading: false,
  pages: 1,
  pageNumber: 10,
  itemPerPage: 10,
  orders: [],
  ...initialFilterState,
  exportedOrders: [],
};

export const getOrders = createAsyncThunk(
  "orders/getOrders",
  async (data, thunkAPI) => {
    try {
      let {
        search,
        // orderType,
        startDate,
        endDate,
        state,
        // pageNumber,
        priority,
        productId,
        sortedAsc,
      } = thunkAPI.getState().orders;
      if (startDate === undefined) startDate = "all";
      if (endDate === undefined) endDate = "all";
      let url =
        thunkAPI.getState().user.user.role === "Clinic"
          ? `${BASEURL}/api/order/getmyorders?itemPerPage=${data.itemPerPage}`
          : `${BASEURL}/api/order/getorders?itemPerPage=${data.itemPerPage}`;

      const resp = await axios.get(
        url +
          `&page=${data.page}` +
          (state !== "all" ? `&state=${state}` : "") +
          (productId !== "all" ? `&productId=${productId}` : "") +
          (search !== "" ? `&searchTerm=${search}` : "") +
          (startDate !== "all" ? `&startDate=${startDate}` : "") +
          (endDate !== "all" ? `&endDate=${endDate}` : "") +
          `&sortedAsc=${sortedAsc}` +
          (priority !== "all" ? `&isUrgent=${priority}` : ""),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const changeOrdersState = createAsyncThunk(
  "orders/changeOrdersState",
  async (data, thunkAPI) => {
    try {
      let url = `${BASEURL}/api/order/change-orders-state?state=${4}`;

      const resp = await axios.put(url, data.orders, {
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const exportPDF = createAsyncThunk(
  "orders/exportPDF",
  async (data, thunkAPI) => {
    try {
      let {
        search,
        // orderType,
        startDate,
        endDate,
        state,
        // pageNumber,
        priority,
        productId,
        sortedAsc,
      } = thunkAPI.getState().orders;
      if (startDate === undefined) startDate = "all";
      if (endDate === undefined) endDate = "all";
      let url = `${BASEURL}/api/order/Export?`;

      const resp = await axios.get(
        url +
          (state !== "all" ? `&state=${state}` : "") +
          (productId !== "all" ? `&productId=${productId}` : "") +
          (search !== "" ? `&searchTerm=${search}` : "") +
          (startDate !== "all" ? `&startDate=${startDate}` : "") +
          (endDate !== "all" ? `&endDate=${endDate}` : "") +
          `&sortedAsc=${sortedAsc}` +
          (priority !== "all" ? `&isUrgent=${priority}` : ""),
        {
          headers: {
            Authorization: thunkAPI.getState().user.user.token,
          },
        }
      );
      return resp.data;
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
export const exportFile = createAsyncThunk(
  "orders/exportFile",
  async (_, thunkAPI) => {
    try {
      const response = await axios.get(`${BASEURL}/api/order/getwordfile`, {
        responseType: "blob",
        headers: {
          Authorization: thunkAPI.getState().user.user.token,
        },
      });

      const contentDispositionHeader = response.headers["content-disposition"];
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDispositionHeader);
      const filename =
        matches && matches[1]
          ? matches[1].replace(/['"]/g, "")
          : "downloaded_file.docx";

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      return { success: true };
    } catch (error) {
      return getTheError(error, thunkAPI);
    }
  }
);
const allOrdersSlice = createSlice({
  name: "allOrders",
  initialState,
  reducers: {
    clearFilters: (state) => {
      return { ...state, ...initialFilterState };
    },
    handleChange: (state, { payload: { name, value } }) => {
      state[name] = value;
    },
    applyFilters: (state, { payload }) => {
      state.startDate = payload?.startDate;
      state.endDate = payload?.endDate;
      state.search = payload?.search;
      state.state = payload?.state;
      state.sortedAsc = payload?.sortedAsc;
      state.productId = payload?.productId;
      state.priority = payload?.priority;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getOrders.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getOrders.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if (payload && payload?.data) {
        state.orders = payload?.data;
        state.pages = payload?.data.pages;
      } else state.orders = [];
    });
    builder.addCase(getOrders.rejected, (state, { payload }) => {
      state.isLoading = false;
      message.error(payload);
    });
    builder.addCase(exportPDF.pending, (state) => {
      // state.isLoading = true;
      state.pdfIsloading = true;
    });
    builder.addCase(exportPDF.fulfilled, (state, { payload }) => {
      state.pdfIsloading = false;
      if (payload && payload?.data) {
        state.exportedOrders = payload?.data;
        // state.pages = payload?.data.pages;
      } else state.orders = [];
    });
    builder.addCase(exportPDF.rejected, (state, { payload }) => {
      state.pdfIsloading = false;
      message.error(payload);
    });
    builder.addCase(changeOrdersState.pending, (state) => {
      // state.isLoading = true;
      state.updateIsLoading = true;
    });
    builder.addCase(changeOrdersState.fulfilled, (state, { payload }) => {
      state.updateIsLoading = false;
      // console.log(payload)
      message.success(payload?.data);
    });
    builder.addCase(changeOrdersState.rejected, (state, { payload }) => {
      state.updateIsLoading = false;
      message.error(payload);
    });
  },
});
export default allOrdersSlice.reducer;
export const { clearFilters, handleChange, applyFilters } =
  allOrdersSlice.actions;
